import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Card } from "react-bootstrap";
import Header from "../helpers/header";

import { databaseFire } from "../../actions/firebase-blog";

const Classes = () => {
  return (
    <div className="library-container2">
      <Header title="מחזורי הדור הבא" back />
      <div className="student-container">
        <Card style={{ backgroundColor: "black", width: "18rem" }}>
          <Card.Img variant="top" src={"/images/graduate-centers.webp"} />
          <Card.Body>
            <Card.Title>מחזור 2024</Card.Title>
            <Link
              className="btn btn-primary"
              to="/library/classes/classesnew/2024"
            >
              כיתות מחזור 2024
            </Link>
          </Card.Body>
        </Card>
      </div>
      <div className="student-container">
        <Card style={{ backgroundColor: "black", width: "18rem" }}>
          <Card.Img variant="top" src={"/images/graduate-centers.webp"} />
          <Card.Body>
            <Card.Title>מחזור 2023</Card.Title>
            <Link
              className="btn btn-primary"
              to="/library/classes/classesnew/2023"
            >
              כיתות מחזור 2023
            </Link>
          </Card.Body>
        </Card>
      </div>

      <div className="student-container">
        <Card style={{ backgroundColor: "black", width: "18rem" }}>
          <Card.Img variant="top" src={"/images/graduate-centers.webp"} />
          <Card.Body>
            <Card.Title>מחזורי 2017-2022</Card.Title>
            <Link className="btn btn-primary" to="/library/classes/classesold">
              כניסה למחזורים ישנים
            </Link>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Classes;
