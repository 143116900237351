import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDAvO281plCg7IIqK63QxAu5Jd60cdUcL4",
  authDomain: "wiki-scratch-745b1.firebaseapp.com",
  projectId: "wiki-scratch-745b1",
  storageBucket: "wiki-scratch-745b1.appspot.com",
  messagingSenderId: "229908131800",
  appId: "1:229908131800:web:a491cad92b3b532e78ccad",
  measurementId: "G-9BNGD97895",
  databaseURL:
    "https://wiki-scratch-745b1-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const secondaryApp = firebase.initializeApp(firebaseConfig, "secondary");

//export const database = secondaryApp.database().ref("/posts");

//export const database = secondaryApp.database().ref("posts");

export const database = secondaryApp.database().ref();

export const databaseFire = secondaryApp.firestore();
