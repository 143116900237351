import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "./ScrollAnimation";
import "./cardsSection.css";

const CardsSection = (props) => {
  return (
    <div className="sectionContainer">
      <ScrollAnimation animationType={"fadeInUp"}>
        <div className="headerCardsSection">
          <h1>{props.title}</h1>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animationType={"fadeInUp"}>
        <div className="cardSectionContainer">
          {props.cardsData.map((card, index) => {
            if (card.external) {
              return (
                <Card className="cardItem zoom" key={index}>
                  <a
                    href={card.to}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "black" }}
                  >
                    <Card.Img
                      variant="top"
                      height={150}
                      src={card.img}
                      alt={card.alt}
                    />
                    <Card.Body>
                      <Card.Title className="cardTextTiltle">
                        {card.title}
                      </Card.Title>
                      <Card.Text
                        className="CardTextContent"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {card.text}
                      </Card.Text>
                      <a
                        className="btn btn-primary cardButton"
                        target="_blank"
                        rel="noopener noreferrer"
                        to={card.to}
                      >
                        {card.button}
                      </a>
                    </Card.Body>
                  </a>
                </Card>
              );
            } else {
              return (
                <Card className="cardItem zoom" key={index}>
                  <Link to={card.to} style={{ color: "black" }}>
                    <Card.Img
                      variant="top"
                      height={150}
                      src={card.img}
                      alt={card.alt}
                    />
                    <Card.Body>
                      <Card.Title className="cardTextTiltle">
                        {card.title}
                      </Card.Title>
                      <Card.Text
                        className="CardTextContent"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {card.text}
                      </Card.Text>
                      <Link className="btn btn-primary cardButton" to={card.to}>
                        {card.button}
                      </Link>
                    </Card.Body>
                  </Link>
                </Card>
              );
            }
          })}
        </div>
      </ScrollAnimation>
    </div>
  );
};
export default CardsSection;
