import { CREATE_CONTACT_US, CREATE_CONTACT_US_ERROR } from "../actions/types";

const INITAL_STATE = {
  answer: "",
  error: "",
};
export default function (state = INITAL_STATE, action) {
  console.log(action.type);
  switch (action.type) {
    case CREATE_CONTACT_US:
      return { ...state, answer: action.payload, error: "" };
    case CREATE_CONTACT_US_ERROR:
      return { ...state, answer: "", error: action.payload };

    default:
      return state;
  }
}
