import React from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import "./imageBackground.css";

const ImageBackground = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const imageSrc = isMobile ? props.mobileSrc : props.desktopSrc;
  return (
    <>
      <Helmet>
        <link rel="preload" href={imageSrc} as="image" />
      </Helmet>
      <div className="image-container">
        <img className="imageBackground" src={imageSrc} alt={props.title} />
        <div className="img-text-overlay">
          <h1 style={{ whiteSpace: "pre-line" }}>{props.title}</h1>
          <p style={{ whiteSpace: "pre-line" }}>{props.subtitle}</p>
          {/* {props.content ? props.content() : ""} */}
        </div>
      </div>
    </>
  );
};
export default ImageBackground;
