import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles

import "./swiperCards.css";
import { EffectCoverflow, Autoplay } from "swiper";

import rightQuote from "../../../assets/right-quote.png";

const SwiperCards = (props) => {
  const renderSlides = () => {
    return props.data.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div class="testimonialBox">
            <img
              src={rightQuote}
              class="quote"
              width={54}
              height={54}
              alt="Quote"
            />
            <div class="data-content">
              <p>{item.comment}</p>
              <div class="details">
                <div class="imgBx">
                  <img src={`images/${item.avatar}.svg`} alt="avatar" />
                </div>
                <h3>
                  {item.name}
                  <br />
                  <span>{item.center}</span>
                </h3>
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          reverseDirection: false,
          pauseOnMouseEnter: true,
        }}
        modules={[EffectCoverflow, Autoplay]}
        //modules={[EffectCoverflow]}
        className="mySwiper"
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
export default SwiperCards;
