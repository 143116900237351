import React, { useEffect, useState } from "react";
import DynamicBlock from "../UI//dynamicBlock";
import PageNotFound from "../template/pagenotfound";
import ImageBackground from "../UI/imageBackground";
import CardsSection from "../UI/cardsSection";
import Contact from "../UI/contact";
import ImageCards from "../UI/imageCards";
import VideoBlock from "../UI/videoBlock";
import ScrollAnimation from "../UI/ScrollAnimation";
import { SchoolCourses } from "../../dictionary/syllabus.json";

import {
  SEO,
  CentersPlacesCardSectionData,
  TechnolgyCards,
} from "../../dictionary/dictionary.json";

import "./coursePage.css";

const CoursePageSchool = (props) => {
  const [courseObject, setCourseObject] = useState([]);
  const [pageStatus, setpageStatus] = useState("loading");
  const { pageID } = props.match.params;

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setpageStatus("loading");
    setCourseObject([]);

    const search = () => {
      try {
        const courseDetails = getData();

        if (courseDetails.CourseInfo) {
          setCourseObject(courseDetails);
          console.log(courseDetails);
          setpageStatus("pageFound");
        } else {
          setpageStatus("pageNotFound");
        }
      } catch {
        setpageStatus("pageNotFound");
      }
    };

    search();
    window.scrollTo(0, 0);
  }, [pageID]);

  const getData = () => {
    return SchoolCourses[pageID];
  };
  const renderVideo = () => {
    if (!courseObject.CourseInfo.courseVideo) {
      return;
    }

    return (
      <div className="courseVideoContainer">
        <VideoBlock id={courseObject.CourseInfo.courseVideo} />
      </div>
    );
  };

  const renderCourse = () => {
    if (pageStatus === "loading") {
      return "loading";
    } else if (pageStatus === "pageFound") {
      if (courseObject.CourseInfo) {
        const { CourseInfo } = courseObject;
        return (
          <React.Fragment>
            <ImageBackground
              title={CourseInfo.courseTitle}
              desktopSrc={CourseInfo.courseImage}
              mobileSrc={CourseInfo.courseImage}
              subtitle={""}
            />
            <div className="shortcutButtons">
              <button
                onClick={() => scrollToSection("register")}
                className="btn btn-success"
              >
                {CourseInfo.courseCallToAction}
              </button>
              <a
                href={"https://wa.me/972554320014"}
                className="btn btn-success"
              >
                לשיחת וואטסאפ מיידית
              </a>
            </div>
            {renderVideo()}
            <div class="courseContentContainer">
              <div className="blockContent">
                <DynamicBlock pageID={CourseInfo.courseContent} block={1} />
              </div>
              {/* <div className="courseImageContent">
                <img src={CourseInfo.courseContentImage} />
              </div> */}
            </div>
            <div style={{ margin: "20px" }}>
              <h1>טכנולוגיות עיקריות שנלמד במהלך הקורס</h1>
              <ImageCards cards={TechnolgyCards[CourseInfo.technologies]} />
            </div>

            <div id="register">
              <Contact />
            </div>
          </React.Fragment>
        );
      } else {
        return <PageNotFound />;
      }
    } else {
      return <PageNotFound />;
    }
  };

  return <div className="course-container">{renderCourse()}</div>;
};
export default CoursePageSchool;
