import React from 'react';

const ComputerIntro = () => {
  return (
    <div style={{ padding: '20px' }}>
      <div style={{ border: '2px solid blue', padding: '20px' }}>
        <h2>כללי</h2>
        <h3 style={{ color: 'orange' }}>עכבר</h3>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.roomrecess.com/mobile/ClickSpeed/play.html"
          className="btn btn-success"
        >
          משחק לאימון בעכבר
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://minimouse.us/"
          className="btn btn-success"
        >
          משחקים לאימון בעכבר 2
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.roomrecess.com/games/DragonDrop/play.html"
          className="btn btn-success"
        >
          משחקים לאימון בעכבר 3
        </a>

        <h3 style={{ color: 'yellowgreen' }}>יצירה</h3>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hugolescargot.com/coloriages/coloriage-en-ligne/"
          className="btn btn-success"
        >
          אתר יצירה וצביעה
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sp-studio.de/"
          className="btn btn-success"
        >
          משחק לעיצוב דמות
        </a>

        <h3 style={{ color: '#FF2255' }}>מקלדת</h3>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://toytheater.com/maze/"
          className="btn btn-success"
        >
          משחק מבוכים
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.yo-yoo.co.il/tools/spiral/"
          className="btn btn-success"
        >
          יצירת ספירלות
        </a>

        <h3 style={{ color: '#4d97ff' }}>אינטרנט</h3>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.pbclibrary.org/mousing/mousercise.htm"
          className="btn btn-success"
        >
          מצא את הלינק
        </a>
      </div>
      <div
        style={{
          border: '2px solid blue',
          padding: '20px',
          marginTop: '20px',
          color: 'black',
        }}
      >
        <h2>כיתה ב'</h2>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.xoox.co.il/wordsearch/"
          className="btn btn-success"
        >
          משחק תפזורת
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.yo-yoo.co.il/tools/crypto/"
          className="btn btn-success"
        >
          יצירת כתב סתרים
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.yo-yoo.co.il/data/game/"
          className="btn btn-success"
        >
          ארץ עיר חי צומח
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hachtava.co.il/typing-heb.html"
          className="btn btn-success"
        >
          חיות ואותיות - משחק הקלדה
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://sense-lang.org/typing/tutor/keyboardingHE.php"
          className="btn btn-success"
        >
          הקלדה עיוורת
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://school.sense-lang.org/he/app/3"
          className="btn btn-success"
        >
          הקלדה מתקפת חייזרים
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wordwall.net/he/resource/31893373/%d7%9e%d7%aa%d7%99%d7%9e%d7%98%d7%99%d7%a7%d7%94/%d7%9e%d7%a9%d7%97%d7%a7%d7%99-%d7%97%d7%99%d7%91%d7%95%d7%a8-%d7%aa%d7%97%d7%95%d7%9d-20"
          className="btn btn-success"
        >
          הכה את החפרפרת
        </a>
      </div>
      <div
        style={{
          border: '2px solid blue',
          padding: '20px',
          marginTop: '20px',
          color: 'black',
        }}
      >
        <h2>משחקי חשיבה מיחשובית</h2>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://i4ds.github.io/CargoBot/?state=1"
          className="btn btn-success"
        >
          משחק קרגובוט
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gameflare.com/online-game/light-bot/"
          className="btn btn-success"
        >
          משחק אורבוט
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://app.codemonkey.com/junior/chapters/sequencing/challenges/1?locale=he&signout=t"
          className="btn btn-success"
        >
          קוד מאנקי ג'וניור - סדר פעולות
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://app.codemonkey.com/beaver-achiever/hour-of-code/1"
          className="btn btn-success"
        >
          קוד מאנקי בונים ונהנים
        </a>
      </div>
    </div>
  );
};
export default ComputerIntro;
