import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createNewContact } from "../../actions/contactUs";
import validateEmails from "../../utils/validateEmails.js";

import { Buttons, Labels, Message } from "../../dictionary/dictionary";

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
    };
  }

  onSubmit(values) {
    // this.props.createNewContact(values, () => {});
    if (values.first_name && values.last_name && values.email && values.phone) {
      this.setState({ error: "" });
      this.props.createNewContact(values, () => {});
    } else {
      this.setState({ error: "נא להקליד את כל הפרטים" });
    }
  }

  renderField(field) {
    const {
      meta: { touched, error },
    } = field;
    //const FieldClass = `inputBox ${touched && error ? "has-danger" : ""}`;
    //const className = "inputBox";
    return (
      <React.Fragment>
        <div className={"inputBox"}>
          <span>{field.lable + ":"}</span>
          <input type="text" {...field.input} />
          <span className="error-field">{touched && error}</span>
        </div>
      </React.Fragment>
    );
  }

  renderTextField(field) {
    return (
      <div className="inputBox">
        <span>{field.lable + ":"}</span>
        <textarea
          type="text"
          {...field.input}
          placeholder={field.ph}
          maxlength={255}
        />
      </div>
    );
  }

  renderError() {
    let error = "";
    if (this.props.error || this.state.error) {
      error = "אירעה שגיאה";
    }
    return error;
  }

  renderByAnswer() {
    if (this.props.answer != "") {
      return (
        <div className="thanks">
          <div className="contact-us-title">
            <h2>{Labels.HN.Thanks}</h2>
          </div>
          <div className="thanks-img-box">
            <img src="images/thank-you.png" alt="thank-you" />
          </div>
        </div>
      );
    }
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <React.Fragment>
        <div className="contact-us-title">
          <h2>הירשמו ליצירת קשר ולקבלת פרטים נוספים</h2>
        </div>
        <form
          className={"formBox"}
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
          noValidate
        >
          <div class="row50">
            <Field
              lable={Labels.HN.First_Name}
              name="first_name"
              component={this.renderField}
            />
            <Field
              lable={Labels.HN.Last_Name}
              name="last_name"
              component={this.renderField}
            />
          </div>
          <div class="row50">
            <Field
              lable={Labels.HN.Phone}
              name="phone"
              component={this.renderField}
            />
            <Field
              lable={Labels.HN.Email}
              name="email"
              component={this.renderField}
              type="email"
            />
          </div>
          <div className="row100">
            <Field
              lable={Labels.HN.Message + " (עד 255 תווים)"}
              name="message"
              component={this.renderTextField}
              ph={"רשמו את ההודעה שלכם..."}
            />
          </div>
          <div className="row100">
            <div className="inputBox input-submit">
              <button type="submit" disabled={submitting}>
                {Buttons.HN.Send}
              </button>
              <span className="form-error">{this.renderError()}</span>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  render() {
    return <React.Fragment>{this.renderByAnswer()}</React.Fragment>;
  }
}

function validate(values) {
  const errors = {};

  errors.email = validateEmails(values.email || "");
  if (!values.first_name) {
    errors.first_name = Message.HN.InsertFirstName;
  }
  if (!values.last_name) {
    errors.last_name = Message.HN.InsertLastName;
  }
  if (!values.email) {
    errors.email = Message.HN.EmailEmpty;
  }
  if (!values.phone) {
    errors.phone = Message.HN.PhoneEmpty;
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    answer: state.contactUs.answer,
    error: state.contactUs.error.message,
  };
}

export default reduxForm({
  validate,
  form: "ContactUsForm",
})(connect(mapStateToProps, { createNewContact })(ContactUs));
