import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CreateGame from "./create_game";
import GameList from "./game_list";
import Header from "../helpers/header";
import "./playground.css";
import { SEO } from "../../dictionary/dictionary.json";
const { PlaygroundT } = SEO;

const Playground = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="playground-container">
      <Helmet>
        <title>{PlaygroundT.title}</title>
        <meta name="description" content={PlaygroundT.desc} />
        <meta name="title" content={PlaygroundT.title} />
      </Helmet>

      <center>
        <div className="title-container">
          <h1 className="rainbowStyle">מגרש המשחקים של סקראץ'</h1>
        </div>
      </center>
      <br />
      <CreateGame />
      <div>
        <GameList type="editor" />
      </div>
    </div>
  );
};

export default Playground;
