import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SwiperCards from "../UI/swiperCards";
import SwiperCardsVideos from "../UI/swiperCardsVideos";
import SwiperCardsPartners from "../UI/swiperCardsPartners";
import AboutMe from "../UI/aboutMe";
import VideoBackground from "../UI/videoBackground";
import ScrollAnimation from "../UI/ScrollAnimation";
import CardsSection from "../UI/cardsSection";
import AboutMethod from "../UI/AboutMethod";
import VideoBlock from "../UI/videoBlock";

// import SwiperCardsEffect from "../UI/swiperCardsEffect";
import Contact from "../UI/contact";
import {
  SEO,
  Comments,
  Videos,
  Partners,
  HomeCardSectionData,
} from "../../dictionary/dictionary.json";
import "./home_page.css";

//import useScript from "../../utils/useScript";
const { Home } = SEO;

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="homeContainer">
      <Helmet>
        <title>{Home.title}</title>
        <meta name="description" content={Home.desc} />
        <meta name="title" content={Home.title} />
        <meta name="keywords" content={Home.tags} />
      </Helmet>
      <div>
        <VideoBackground
          videoSrc={"/images/home/home_header_video.mp4"}
          title={"הדור הבא"}
          subtitle={"למצוינות ומעבר לכך"}
        />
        <div className="courseVideoHomeContainer">
          <VideoBlock id={"7QnkjQn8XQ0"} />
        </div>
        <ScrollAnimation animationType="zoomIn">
          <div className="aboutHomeContainer">
            <div className="aboutHomeContent">
              <h2>חזון הדור הבא</h2>
              <p
                style={{
                  textAlign: "right",
                  fontSize: "20px",
                  padding: "10px",
                }}
              >
                להעניק
                <span style={{ color: "#061799", fontWeight: "bold" }}>
                  {" "}
                  לדור הבא{" "}
                </span>
                כישורים חיוניים לחיים ולקדם חדשנות וטכנולוגיה בישראל.
              </p>
              <h2>הדור הבא - מובילים למצוינות טכנולוגית</h2>
              <p style={{ textAlign: "right", fontSize: "20px" }}>
                הדור הבא הוא מיזם חינוכי טכנולוגי שמטרתו ללמד ילדים וילדות את
                מקצועות התכנות בעזרת בניית משחקים ורובוטיקה.
                <br />
                אנו מאמינים שתכנות הוא מיומנות חיונית לעתיד, וכי היא יכולה לעזור
                לדור הצעיר לפתח את כישוריהם הטכניים, היצירתיים והחשיבתיים.
                <br />
                אנו מציעים מגוון רחב של תוכניות לימודים, המותאמות לגילאים ולרמות
                הידע השונות של התלמידים. התוכניות שלנו מבוססות על שיטה חווייתית
                ומהנה, המעודדת את התלמידים ללמוד דרך עשייה. אנו מאמינים שדרך זו
                היא הדרך הטובה ביותר ללמידה, וכי היא מאפשרת לתלמידים לרכוש ידע
                וכישורים תוך כדי הנאה.
                <br />
                המדריכים שלנו הם מומחים בתחום התכנות ומומחים בהוראה חווייתית
                ומהנה. הם יודעים כיצד ליצור סביבת למידה מרתקת ומעוררת השראה,
                וכיצד לגרום לתלמידים ללמוד תוך כדי הנאה.
                <br />
                אם אתם מחפשים תוכניות לימוד במקצועות החינוך הטכנולוגי, אנו
                מזמינים אתכם להצטרף איתנו למסע וליצור איתנו קשר לקבלת פרטים
                נוספים.
              </p>

              <div>
                <button
                  onClick={() => scrollToSection("register")}
                  className="btn btn-success"
                  style={{ marginBottom: "10px" }}
                >
                  להשארת פרטים{" "}
                </button>
                <a
                  className="btn btn-success"
                  href="https://wa.me/972545405444"
                  style={{ color: "white", marginBottom: "10px" }}
                >
                  לשיחת וואטסאפ{" "}
                </a>
              </div>
            </div>
            <div className="aboutHomeImage">
              <img src="images/home/the_future.webp" className="theFutureImg" />
            </div>
          </div>
        </ScrollAnimation>
        <AboutMethod />
        <CardsSection
          title={HomeCardSectionData.title}
          cardsData={HomeCardSectionData.cardsData}
        />

        <div style={{ textAlign: "right" }}>
          <AboutMe />
        </div>
        <div className="partners-container">
          <h2>בין לקוחותינו</h2>
          <SwiperCardsPartners data={Partners} />
        </div>
        <div className="comments-container">
          <h2>מדברים עלינו</h2>
          <SwiperCards data={Comments.data} />
        </div>
        <div className="comments-container">
          <h2>תלמידים והורים ממליצים</h2>
          <SwiperCardsVideos data={Videos.data} />
        </div>

        <div id="register">
          <Contact />
        </div>
        {/* <Contact /> */}
      </div>
    </div>
  );
};

export default HomePage;
