import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import { connect } from "react-redux";
import User from "./user";
import logoIMG from "../../../assets/hadoraba-logo.webp";
import dictionary from "../../dictionary/dictionary.json";
import scratchLogo from "../../../assets/scratch-logo.png";

const { MenuItems, Activities } = dictionary;
const gefen = 'תוכניות גפ"ן';

const routes = [
  { to: "/", title: MenuItems.HN.Home, exact: true, index: 1 },

  { to: "/centersActivities", title: MenuItems.HN.CentersActivities, index: 2 },
  { to: "/onlineActivities", title: MenuItems.HN.OnlineActivities, index: 3 },
  { to: "/schoolsActivities", title: MenuItems.HN.SchoolsActivities, index: 4 },

  { to: "/library", title: MenuItems.HN.Library, index: 5 },
  // { to: "/about", title: MenuItems.HN.About, index: 6 },
  { to: "/playground", title: MenuItems.HN.Playground, index: 7 },
  { to: "/wiki", title: MenuItems.HN.Wiki, index: 8 },
  { to: "/blog", title: MenuItems.HN.Blog, index: 9 },
  { to: "/students", title: MenuItems.HN.Students, index: 8 },
];

const activities = [
  {
    to: "/centersActivities",
    title: "כל הפעילויות ומיקומים",
    index: 0,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/centersActivities/CompouterForKids",
    title: "הצעדים הראשונים של הדור הבא",
    index: 1,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/centersActivities/ScratchCourse",
    title: "מתכנתי הדור הבא",
    index: 2,
    img: "/images/courses/hadoraba-course.webp",
  },
  {
    to: "/centersActivities/MakersCourse",
    title: "המייקרים של הדור הבא",
    index: 3,
    img: "/images/courses/makers.webp",
  },
  {
    to: "/centersActivities/ScratchGirlsCourse",
    title: "מתכנתות הדור הבא",
    index: 4,
    img: "/images/courses/girls-programming.webp",
  },
  {
    to: "/centersActivities/AdvancedCourse",
    title: "יזמי הדור הבא - ממשיכים",
    index: 5,
    img: "/images/courses/advanced.webp",
  },
  {
    to: "/centersActivities/MasterCourse",
    title: "יזמי הדור הבא - מתקדמים",
    index: 6,
    img: "/images/courses/advanced.webp",
  },
];

const online_activities = [
  {
    to: "/onlineActivities",
    title: "כל הפעילויות",
    index: 0,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/onlineActivities/ScratchCourseOnline",
    title: "מתכנתי הדור הבא",
    index: 1,
    img: "/images/courses/hadoraba-course.webp",
  },
  {
    to: "/onlineActivities/PythonCourseOnline",
    title: "קורס פייתון",
    index: 2,
    img: "/images/courses/makers.webp",
  },
  {
    to: "/onlineActivities/AICourseOnline",
    title: "בינה מלאכותית",
    index: 3,
    img: "/images/courses/makers.webp",
  },
];

const School_activities = [
  {
    to: "/schoolsActivities",
    title: "כל תוכניות הלימוד",
    index: 0,
    img: "/images/courses/gefen.webp",
  },
  {
    to: "/schoolsActivities/ScratchJuniorSchool",
    title: "סקראץ' ג'וניור",
    index: 1,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/ComputerForKidsSchool",
    title: "צעדים ראשונים של הדור הבא",
    index: 2,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/NgScratchSchool",
    title: "סקראץ'",
    index: 3,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/NgScratchCreativeSchool",
    title: "אומנים דיגיטליים",
    index: 4,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/EntNgScratchSchool",
    title: "יזמי הדור הבא - סקראץ'",
    index: 5,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/NgOctoStudioSchool",
    title: "OctoStudio",
    index: 6,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/EntNgAISchool",
    title: "בינה מלאכותית",
    index: 7,
    img: "/images/courses/first-steps.webp",
  },
  {
    to: "/schoolsActivities/NgMakersSchool",
    title: "מיקרוביט",
    index: 8,
    img: "/images/courses/hadoraba-course.webp",
  },
  {
    to: "/schoolsActivities/EntNgAppInvSchool",
    title: "ממציא האפליקציות",
    index: 9,
    img: "/images/courses/makers.webp",
  },
  {
    to: "/schoolsActivities/EntNgPythonSchool",
    title: "Python",
    index: 10,
    img: "/images/courses/advanced.webp",
  },
  {
    to: "/schoolsActivities/EntNgWebDevSchool",
    title: "פיתוח אתרים",
    index: 11,
    img: "/images/courses/girls-programming.webp",
  },
  {
    to: "/schoolsActivities/EntNgATeacherSchool",
    title: "מורי הדור הבא",
    index: 12,
    img: "/images/courses/girls-programming.webp",
  },
];

const Navbar = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [onSlide, setOnSlide] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState(0);

  const checkYOffset = () => {
    if (window.pageYOffset > 0) {
      setOnSlide(true);
    } else {
      setOnSlide(false);
    }
  };

  useEffect(() => {
    //for the navbar shadow and change background
    const changeShadow = () => {
      window.addEventListener("scroll", (e) => {
        checkYOffset();
      });
      checkYOffset();
    };
    const handleMedia = (QueryMedia) => {
      if (QueryMedia.matches) {
        setIsDesktop(false);
        setIsMobileMenuOpen(false);
      } else {
        setIsDesktop(true);
        setIsMobileMenuOpen(false);
      }
    };
    //listen to change of screen width
    const currMedia = window.matchMedia("(max-width: 1260px)");
    currMedia.addListener(handleMedia);
    handleMedia(currMedia);
    changeShadow();
  }, []);

  const handleShowMenu = (show, i) => {
    setShowMenu(show);
    setCurrentDropDown(i);
  };

  const renderDesktop = (
    <nav>
      {routes.map((route) => {
        if (route.to === "/centersActivities") {
          return (
            <NavLink
              key={route.to}
              onMouseEnter={() => handleShowMenu(true, route.index)}
              onMouseLeave={() => handleShowMenu(false, route.index)}
              to={route.to}
              activeClassName="current-menu-item"
            >
              <div className="action">
                <div className="menu-header">{route.title}</div>
                <div
                  className={`menu ${
                    showMenu && currentDropDown === route.index ? "active" : ""
                  } `}
                  onMouseEnter={() => handleShowMenu(true, route.index)}
                  onMouseLeave={() => handleShowMenu(false, route.index)}
                >
                  <ul>
                    {activities.map((act) => {
                      return (
                        <li key={act.index} onClick={() => setShowMenu(false)}>
                          <img src={act.img} />
                          <Link to={act.to}>{act.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </NavLink>
          );
        } else if (route.to === "/onlineActivities") {
          return (
            <NavLink
              key={route.to}
              onMouseEnter={() => handleShowMenu(true, route.index)}
              onMouseLeave={() => handleShowMenu(false, route.index)}
              to={route.to}
              activeClassName="current-menu-item"
            >
              <div className="action">
                <div className="menu-header">{route.title}</div>
                <div
                  className={`menu ${
                    showMenu && currentDropDown === route.index ? "active" : ""
                  } `}
                  onMouseEnter={() => handleShowMenu(true, route.index)}
                  onMouseLeave={() => handleShowMenu(false, route.index)}
                >
                  <ul>
                    {online_activities.map((act) => {
                      return (
                        <li key={act.index} onClick={() => setShowMenu(false)}>
                          <img src={act.img} />
                          <Link to={act.to}>{act.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </NavLink>
          );
        } else if (route.to === "/schoolsActivities") {
          return (
            <NavLink
              key={route.to}
              onMouseEnter={() => handleShowMenu(true, route.index)}
              onMouseLeave={() => handleShowMenu(false, route.index)}
              to={route.to}
              activeClassName="current-menu-item"
            >
              <div className="action">
                <div className="menu-header">{route.title}</div>
                <div
                  className={`menu ${
                    showMenu && currentDropDown === route.index ? "active" : ""
                  } `}
                  onMouseEnter={() => handleShowMenu(true, route.index)}
                  onMouseLeave={() => handleShowMenu(false, route.index)}
                >
                  <ul>
                    {School_activities.map((act) => {
                      return (
                        <li key={act.index} onClick={() => setShowMenu(false)}>
                          <img src={act.img} />
                          <Link to={act.to}>{act.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </NavLink>
          );
        } else if (route.to === "ng-programmers") {
          return (
            <a
              href={"http://bit.ly/3lkWB2o"}
              activeClassName="current-menu-item"
              className={"store-nav-link"}
            >
              {route.title}
            </a>
          );
        } else if (route.to === "/students") {
          if (props.user.displayName === process.env.REACT_APP_STUDENTS) {
            return (
              <NavLink
                key={route.to}
                exact={route.exact}
                to={route.to}
                activeClassName="current-menu-item"
                className={"student-nav-link"}
              >
                {route.title}
              </NavLink>
            );
          }
        } else {
          return (
            <NavLink
              key={route.to}
              exact={route.exact}
              to={route.to}
              activeClassName="current-menu-item"
            >
              {route.title}
            </NavLink>
          );
        }
      })}
    </nav>
  );

  const renderMobile = (
    <div className="mobile-menu">
      <div className="mobile-menu-header">
        <Hamburger
          size={30}
          rounded
          duration={0.8}
          direction="right"
          toggled={isMobileMenuOpen}
          toggle={setIsMobileMenuOpen}
          color={isMobileMenuOpen ? "#ff5d94" : "#fff"}
        />
      </div>
      <div className={`mobile-menu-list ${isMobileMenuOpen ? "active" : ""}`}>
        {routes.map((route) => {
          if (route.to === "/centersActivities") {
            return (
              <React.Fragment key={route.index}>
                <div className="dropdown-header">
                  <a
                    onClick={() => {
                      if (route.index !== currentDropDown) {
                        setCurrentDropDown(route.index);
                        setIsDropDownMenuOpen(true);
                      } else {
                        setIsDropDownMenuOpen(!isDropDownMenuOpen);
                        setCurrentDropDown(route.index);
                      }
                    }}
                    activeClassName="current-menu-item "
                  >
                    {route.title}
                    <span>
                      <i
                        className={`fas fa-chevron-circle-down arrow-icon ${
                          isDropDownMenuOpen && route.index === currentDropDown
                            ? "open"
                            : ""
                        }`}
                      />
                    </span>
                  </a>
                </div>
                <div
                  className={`dropdown ${
                    isDropDownMenuOpen && route.index === currentDropDown
                      ? "active"
                      : ""
                  }`}
                >
                  {activities.map((act) => {
                    return (
                      <NavLink
                        key={act.to}
                        to={act.to}
                        activeClassName="current-menu-item"
                        onClick={() => {
                          setIsMobileMenuOpen(false);
                          setIsDropDownMenuOpen(false);
                          setCurrentDropDown(route.index);
                        }}
                      >
                        {act.title}
                      </NavLink>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          } else if (route.to === "/onlineActivities") {
            return (
              <React.Fragment key={route.index}>
                <div className="dropdown-header">
                  <a
                    onClick={() => {
                      if (route.index !== currentDropDown) {
                        setCurrentDropDown(route.index);
                        setIsDropDownMenuOpen(true);
                      } else {
                        setIsDropDownMenuOpen(!isDropDownMenuOpen);
                        setCurrentDropDown(route.index);
                      }
                    }}
                    activeClassName="current-menu-item "
                  >
                    {route.title}
                    <span>
                      <i
                        className={`fas fa-chevron-circle-down arrow-icon ${
                          isDropDownMenuOpen && route.index === currentDropDown
                            ? "open"
                            : ""
                        }`}
                      />
                    </span>
                  </a>
                </div>
                <div
                  className={`dropdown ${
                    isDropDownMenuOpen && route.index === currentDropDown
                      ? "active"
                      : ""
                  }`}
                >
                  {online_activities.map((act) => {
                    return (
                      <NavLink
                        key={act.to}
                        to={act.to}
                        activeClassName="current-menu-item"
                        onClick={() => {
                          setIsMobileMenuOpen(false);
                          setIsDropDownMenuOpen(false);
                          setCurrentDropDown(route.index);
                        }}
                      >
                        {act.title}
                      </NavLink>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          } else if (route.to === "/schoolsActivities") {
            return (
              <React.Fragment key={route.index}>
                <div className="dropdown-header">
                  <a
                    onClick={() => {
                      if (route.index !== currentDropDown) {
                        setCurrentDropDown(route.index);
                        setIsDropDownMenuOpen(true);
                      } else {
                        setIsDropDownMenuOpen(!isDropDownMenuOpen);
                        setCurrentDropDown(route.index);
                      }
                    }}
                    activeClassName="current-menu-item "
                  >
                    {route.title}
                    <span>
                      <i
                        className={`fas fa-chevron-circle-down arrow-icon ${
                          isDropDownMenuOpen && route.index === currentDropDown
                            ? "open"
                            : ""
                        }`}
                      />
                    </span>
                  </a>
                </div>
                <div
                  className={`dropdown ${
                    isDropDownMenuOpen && route.index === currentDropDown
                      ? "active"
                      : ""
                  }`}
                >
                  {School_activities.map((act) => {
                    return (
                      <NavLink
                        key={act.to}
                        to={act.to}
                        activeClassName="current-menu-item"
                        onClick={() => {
                          setIsMobileMenuOpen(false);
                          setIsDropDownMenuOpen(false);
                          setCurrentDropDown(route.index);
                        }}
                      >
                        {act.title}
                      </NavLink>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          } else if (route.to === "/students") {
            if (props.user.displayName === process.env.REACT_APP_STUDENTS) {
              return (
                <NavLink
                  key={route.to}
                  exact={route.exact}
                  to={route.to}
                  activeClassName="current-menu-item"
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                >
                  {route.title}
                </NavLink>
              );
            }
          } else if (route.to === "ng-programmers") {
            return (
              <a
                href={"http://bit.ly/3lkWB2o"}
                activeClassName="current-menu-item"
                onClick={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
              >
                {route.title}
              </a>
            );
          } else {
            return (
              <NavLink
                key={route.index}
                exact={route.exact}
                to={route.to}
                activeClassName="current-menu-item"
                onClick={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
              >
                {route.title}
              </NavLink>
            );
          }
        })}
      </div>
    </div>
  );

  return (
    <div className={`hadoraba-navbar ${onSlide ? "inSlide" : ""}`}>
      <NavLink exact to="/" className="logo">
        <img src={logoIMG} />
      </NavLink>
      {isDesktop ? renderDesktop : renderMobile}
      <User />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(Navbar);
