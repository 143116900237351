import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import _ from "lodash";
import Header from "../helpers/header";

import webImg from "../../../assets/websiteImg.jpg";

const { websites } = require("../../utils/student_websites.json");

class StudentsWebsOLD extends Component {
  renderWebsites(webs, id) {
    return _.map(webs, (web, key) => {
      return (
        <div className="student-container" key={key}>
          <Card style={{ backgroundColor: "black", width: "18rem" }}>
            <Card.Img variant="top" src={webImg} alt={web.name} />
            <Card.Body>
              <Card.Title>
                {web.firstName} {web.lastName}
              </Card.Title>
              <Card.Text>{web.title}</Card.Text>
              <Link
                className="btn btn-primary"
                to={`/library/classes/classesold/${id}/${key}`}
              >
                כנס לקישור
              </Link>
            </Card.Body>
          </Card>
        </div>
      );
    });
  }
  render() {
    const { id } = this.props.match.params;
    const webs = websites[id]["StudentWebsites"];
    return (
      <div className="library-container2">
        <Header title={websites[id].Name} back />
        <div>{this.renderWebsites(webs, id)}</div>
      </div>
    );
  }
}

export default StudentsWebsOLD;
