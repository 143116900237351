import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../helpers/header";

import { SEO } from "../../dictionary/dictionary.json";
import "./wiki.css";

const { WikiT } = SEO;
// change from old to new
const newWiki = true;
const strBlocks = newWiki ? "blocks" : "blocksOld";

const articlesLinks = [
  {
    id: 1,
    to: "clones",
    title: "כפילים",
    img: "",
  },
  {
    id: 2,
    to: "bugs",
    title: "באגים",
    img: "",
  },
  {
    id: 3,
    to: "grid-system",
    title: "מערכת הצירים",
    img: "",
  },
  {
    id: 4,
    to: "programming-lang",
    title: "שפת תכנות",
    img: "",
  },
  {
    id: 5,
    to: "share-project",
    title: "שיתוף פרויקט",
    img: "",
  },
];

const Wiki = (props) => {
  const renderArticles = () => {
    return articlesLinks.map((article) => {
      return (
        <div className="wiki-container">
          <Link
            className="wiki-flexbox"
            style={{ height: "200px" }}
            to={`/wiki/article/${article.to}`}
          >
            <div className="circle" style={{ backgroundColor: "#F8AA36" }} />
            <h3>{article.title}</h3>
          </Link>
        </div>
      );
    });
  };

  return (
    <div>
      <Helmet>
        <title>{WikiT.title}</title>
        <meta name="description" content={WikiT.desc} />
        <meta name="title" content={WikiT.title} />
      </Helmet>
      <center>
        <Header title="מאגר ידע בסקראץ'" />
      </center>
      <br />
      <br />
      <h1>בלוקים</h1>
      <div className="wiki-container">
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/motion`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#4C97FF" }} />
          <h3>תנועה</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/looks`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#9966FF" }} />
          <h3>מראה</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/sound`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#D65CD6" }} />
          <h3>צלילים</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/events`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#FFD500" }} />
          <h3>אירועים</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/control`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#FFAB19" }} />
          <h3>בקרה</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/sensing`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#4CBFE6" }} />
          <h3>חיישנים</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/operators`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#40BF4A" }} />
          <h3>מפעילים</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/variables`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#FF8C1A" }} />
          <h3>משתנים</h3>
        </div>
        <div
          className="wiki-flexbox"
          onClick={() => {
            return props.history.push(`/wiki/${strBlocks}/my-blocks`);
          }}
        >
          <div className="circle" style={{ backgroundColor: "#FF6680" }} />
          <h5>הלבנים שלי</h5>
        </div>
      </div>

      {/* article section */}
      <h1>מאמרים</h1>
      <div className="wiki-container">{renderArticles()}</div>
    </div>
  );
};

export default Wiki;
