import {
  LOG_IN,
  REGISTER,
  CHECK_AUTH,
  LOG_OUT,
  LOG_IN_CONFIRM,
  LOG_IN_ERROR,
  REGISTER_ERROR,
  CLEAR_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  user: {},
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOG_IN_CONFIRM:
      return { ...state, user: action.payload };
    case LOG_IN:
      return { ...state, user: action.payload };
    case LOG_IN_ERROR:
      return { ...state, error: action.payload };
    case LOG_OUT:
      return { ...state, user: {} };
    case CLEAR_ERROR:
      return { ...state, error: null };
    case REGISTER:
      return { ...state, user: action.payload };
    case REGISTER_ERROR:
      return { ...state, error: action.payload };
    case CHECK_AUTH:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
