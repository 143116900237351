import 'bootstrap-rtl/dist/css/bootstrap-rtl.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import ReduxToastr from 'react-redux-toastr';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import './css/style.css';
import './css/styleMobile.css';
import reducers from './js/reducers';

import Layout from './js/components/template/layout';
//import Payment from "./js/components/template/payment";
// import About from "./js/components/about/about";
import HomePage from './js/components/home/home_page';

import ActivitesCenters from './js/components/activities/activities-centers.js';
import CoursePage from './js/components/activities/coursePage';
import ActivitesOnline from './js/components/activities/activities-online';
import CoursePageOnline from './js/components/activities/coursePageOnline';
import ActivitesSchools from './js/components/activities/activities-schools';
import CoursePageSchool from './js/components/activities/coursePageSchool';

import DynamicPage from './js/components/dynamicPage/DynamicPage';

import Playground from './js/components/playground/playground';
//import Discussion from './js/components/discussion/discussion';
import ShowGame from './js/components/playground/show_game';
import Library from './js/components/library/library';
import LibraryDynamicPage from './js/components/library/libraryDynamicPage';
// import Videos from "./js/components/library/videos";
// import Links from "./js/components/library/links";
import ComputerIntro from './js/components/library/ComputerIntro';
import Classes from './js/components/library/classes';
import ClassesByYear from './js/components/library/classesByYear';
import HtmlViewer from './js/components/library/html_viewer';
import StudentWebs from './js/components/library/students_webs';

import ClassesOLD from './js/components/library/classesOLD';
import HtmlViewerOLD from './js/components/library/html_viewerOLD';
import OLD from './js/components/library/students_websOLD';

// import Files from "./js/components/library/files";
import Wiki from './js/components/wiki/wiki';
import Blocks from './js/components/wiki/blocks';
import BlocksByCategory from './js/components/wiki/BlocksByCategory';
import ShowPost from './js/components/wiki/ShowPost';
import PageNotFound from './js/components/template/pagenotfound';

import Students from './js/components/students/students';
import StudentDynamicPage from './js/components/students/studentDynamicPage';
// import DynamicStudent from "./js/components/students/archive/dynamicStudent";
// import ComputerTalan from "./js/components/students/archive/computerTalan";
// import ProgrammingScratch from "./js/components/students/archive/programmingScratch";

import ScratcherPart1 from './js/components/scratcherGuide/scratcherPart1';
import ScratcherPart2 from './js/components/scratcherGuide/scratcherPart2';
import ScratcherPart3 from './js/components/scratcherGuide/scratcherPart3';
import ScratcherPart4 from './js/components/scratcherGuide/scratcherPartH';
// import paymentPage from "./js/components/scratcherGuide/paymentPage";

import Store from './js/components/store/store.js';
import ThankYouPage from './js/components/store/thankyoupage.js';

import Blog from './js/components/blog/blog';
import ShowBlog from './js/components/blog/ShowBlog';

import 'animate.css';
const newWiki = false;

import axios from 'axios';
import StudentsWebsOLD from './js/components/library/students_websOLD';
window.axios = axios;

const createStoreWithMiddleware = applyMiddleware(promise, thunk)(createStore);

const options = {
  okText: 'כן',
  cancelText: 'לא',
};

ReactDOM.render(
  <Provider
    store={createStoreWithMiddleware(
      reducers,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )}
  >
    <div>
      <BrowserRouter>
        <Layout>
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="page"
                  timeout={500}
                >
                  <Switch>
                    {/* <Route path="/about" component={About} /> */}

                    <Route
                      path="/centersActivities/:pageID"
                      component={CoursePage}
                    />
                    <Route
                      path="/centersActivities"
                      component={ActivitesCenters}
                    />
                    <Route
                      path="/schoolsActivities/:pageID"
                      component={CoursePageSchool}
                    />
                    <Route
                      path="/schoolsActivities"
                      component={ActivitesSchools}
                    />
                    <Route
                      path="/onlineActivities/:pageID"
                      component={CoursePageOnline}
                    />
                    <Route
                      path="/onlineActivities"
                      component={ActivitesOnline}
                    />

                    {/* <Route path="/payment/:product/:sum" component={Payment} /> */}
                    <Route path="/playground/:id" component={ShowGame} />
                    <Route path="/playground" component={Playground} />
                    {/* CLASSES PROJECTS */}
                    <Route
                      path="/library/classes/classesnew/:year/:classId/:id"
                      component={HtmlViewer}
                    />
                    <Route
                      path="/library/classes/classesold/:classId/:id"
                      component={HtmlViewerOLD}
                    />

                    <Route
                      path="/library/classes/classesnew/:year/:id"
                      component={StudentWebs}
                    />
                    <Route
                      path="/library/classes/classesold/:id"
                      component={StudentsWebsOLD}
                    />

                    <Route
                      path="/library/classes/classesnew/:year"
                      component={ClassesByYear}
                    />
                    <Route
                      path="/library/classes/classesold"
                      component={ClassesOLD}
                    />

                    <Route path="/library/classes" component={Classes} />
                    {/* 
                    <Route path="/library/links" component={Links} />
                    <Route path="/library/videos" component={Videos} />
                    <Route path="/library/files" component={Files} /> */}

                    <Route
                      path="/library/computerIntroOld"
                      component={ComputerIntro}
                    />
                    <Route path="/library/:id" component={LibraryDynamicPage} />
                    <Route path="/library" component={Library} />

                    <Route
                      path="/wiki/blocks/:category/:id"
                      component={ShowPost}
                    />
                    <Route
                      path="/wiki/blocks/:category"
                      component={BlocksByCategory}
                    />
                    <Route
                      path="/wiki/blocksOld/:category"
                      component={Blocks}
                    />
                    <Route
                      path="/wiki/article/:pageID"
                      component={DynamicPage}
                    />
                    <Route path="/wiki" component={Wiki} />

                    <Route path="/blog/:id" component={ShowBlog} />
                    <Route path="/blog" component={Blog} />

                    {/* students area */}
                    {/* <Route
                      path="/students/computerTalan"
                      component={ComputerTalan}
                    />
                    {
                      <Route
                        path="/students/programmingScratch"
                        component={ProgrammingScratch}
                      />
                    } */}
                    <Route
                      path="/students/:id"
                      component={StudentDynamicPage}
                    />
                    <Route path="/students" component={Students} />

                    {/*STORE*/}
                    <Route
                      path="/store/thankyoupage"
                      component={ThankYouPage}
                    />
                    <Route path="/store" component={Store} />

                    {/*book area*/}
                    <Route
                      path="/books/scratcherGuide/1"
                      component={ScratcherPart1}
                    />
                    <Route
                      path="/books/scratcherGuide/2"
                      component={ScratcherPart2}
                    />
                    <Route
                      path="/books/scratcherGuide/3"
                      component={ScratcherPart3}
                    />
                    <Route
                      path="/books/scratcherGuide/4"
                      component={ScratcherPart4}
                    />
                    {/* <Route
              path="/books/scratcherGuide/paymentPage"
              component={paymentPage}
            /> */}
                    {/* store */}
                    {/* <Route path="/store" component={Store} /> */}
                    {/* <Route path="/wiki" component={testpage} /> */}
                    <Route path="/article/:pageID" component={DynamicPage} />
                    <Route exact path="/index.html" component={HomePage} />
                    <Route exact path="/" component={HomePage} />
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </Layout>
      </BrowserRouter>

      <ReduxToastr
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates
        position="top-left"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        className="toaster"
        confirmOptions={options}
      />
    </div>
  </Provider>,
  document.getElementById('root')
);
