import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./library.css";

import { LibrarySubjects, SEO } from "../../dictionary/dictionary.json";
const { LibraryT } = SEO;

const Library = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderLibrarySubjects = () => {
    return LibrarySubjects.map((subject) => {
      console.log(subject);
      return (
        <div>
          <Link to={`/library/${subject.link}`} className="subject-card">
            <img src={subject.image} />
            <h3>{subject.name}</h3>
            <p>{subject.desc}</p>
            <button className="btn btn-success">{subject.audience}</button>
          </Link>
        </div>
      );
    });
  };

  return (
    <div>
      <Helmet>
        <title>{LibraryT.title}</title>
        <meta name="description" content={LibraryT.desc} />
        <meta name="title" content={LibraryT.title} />
      </Helmet>
      <div className="library-subjects-container">
        <h2>מאגר ידע ללימודי תכנות ורובוטיקה</h2>
        <div className="subjects-container">{renderLibrarySubjects()}</div>
      </div>
    </div>
  );
};

export default Library;
