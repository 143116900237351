import React, { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import { databaseFire } from "../../actions/firebase-blog";

const ShowBlog = (props) => {
  const [currPost, setcurrPost] = useState([]);
  const { id } = props.match.params;
  //todo: need to fetch only the block images and not all the posts
  useEffect(() => {
    const search = async () => {
      const data = {};
      try {
        console.log("id", id);
        const doc = await databaseFire.collection("blog-posts").doc(id).get();
        if (doc.exists) {
          setcurrPost(doc.data());
        } else {
          console.log("No such document!");
        }
      } catch {
        console.log("fetch faild");
      }
    };

    search();
  }, []);

  const renderBlog = () => {
    if (currPost.body) {
      return (
        <div className="containerShowBlog">
          {/* <div className="header-img-blog">{renderHTML(currPost.blogImg)}</div> */}

          <h2 className="blog-title">{currPost.blogTitle}</h2>
          {/* <div className="blog-sub-title">
            <h3>{currPost.title}</h3>
          </div> */}
          <div className="blog-text-explain">{renderHTML(currPost.body)}</div>
        </div>
      );
    }
    return "";
  };

  return (
    <div>
      <div>{renderBlog()}</div>
    </div>
  );
};
export default ShowBlog;
