import React from "react";
import rotate from "../../../assets/rotate.gif";
import { Message } from "../../dictionary/dictionary.json";

const RotateMsg = () => {
  return (
    <div>
      <center>
        <h1>{Message.HN["Please Rotate Your Mobile"]}</h1>
        <img src={rotate} alt="הדור הבא" />
      </center>
    </div>
  );
};

export default RotateMsg;
