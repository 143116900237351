import React, { useState } from "react";
import Lottie from "react-lottie";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Modal } from "react-bootstrap";

import playButton from "../../../assets/play-button.json";

// Import Swiper styles

import "./swiperCards.css";
import { EffectCoverflow } from "swiper";

const SwiperCardsVideos = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: playButton,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderSlides = () => {
    return props.data.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div class="testimonialBoxVideo">
            <div class="video-content-slide">
              <img
                src={item.thumb}
                alt="Video Thumbnail"
                className="swiper-lazy" // Swiper lazy load class
              />
              <div class="play-button" onClick={() => openModal(item.url)}>
                <Lottie options={defaultOptions} height={100} width={100} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        }}
        lazy={true} // Enable lazy loading
        preloadImages={false} // Disable preloading of all images
        watchSlidesVisibility={true} // Ensure only visible slides are loaded
        modules={[EffectCoverflow]}
        className="mySwiperVideos"
      >
        {renderSlides()}
      </Swiper>
      <Modal show={isModalOpen} onHide={closeModal} className="modal-container">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="videoModal">
          {isModalOpen && (
            <iframe
              src={`https://www.youtube.com/embed/${selectedVideoUrl}?rel=0`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SwiperCardsVideos;
