import React from "react";
import ImageCards from "./imageCards";
import ScrollAnimation from "./ScrollAnimation";

import { motivationCards } from "../../dictionary/dictionary.json";
import "./AboutMethod.css";

const AboutMethod = () => {
  return (
    <div className="aboutMethodContainer">
      <ScrollAnimation animationType={"fadeInBottomRight"}>
        <h1>דל"ת להצלחה</h1>
        <h1>שיטת הלימוד הייחודית והאפקטיבית של הדור הבא</h1>
      </ScrollAnimation>

      <div className="aboutMethodSectionContainer">
        <div className="methodSectionParag">
          <ScrollAnimation animationType={"fadeInRight"}>
            <div className="methodSection">
              <p>
                <span>ד</span>מיון – טיפוח יצירתיות דיגיטלית וחשיבה מיחשובית
                <br />
                <span>ל</span>מידה – לימוד אתגרי וחוויתי
                <br />
                <span>ת</span>כנות – נוציא לאור את הדמיון והלימוד לשפת המחשב
              </p>
            </div>
            <div className="methodExplain">
              <p>
                דל"ת להצלחה - שיטת לימוד ייחודית שפותחה ע"י צוות מומחי מיזם הדור
                הבא ושואבת את השראתה מעקרונות לימודי הטכנולוגיה בעולם המערבי
                (STEM): תהליך למידה רב-תחומי חדשני, המשלב מקצועות ליבה מדעיים,
                מתמטיים, טכנולוגיים והנדסיים ומאפשר לילדים:
                <br /> • למידה חווייתית מבוססת חקר
                <br />
                • יישום תהליכי פיתוח הנדסי מאתגרים
                <br />
                • בניית פרויקטים משותפים
                <br />• פתרון בעיות
              </p>
            </div>
          </ScrollAnimation>
        </div>

        <div className="methodImageContainer">
          <ScrollAnimation animationType={"fadeInLeft"}>
            <img src="images/courses/first-steps-lg.webp" />
          </ScrollAnimation>
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        <ScrollAnimation animationType={"fadeInUp"}>
          <h1>כל מה שטוב לעתיד הילדים</h1>
        </ScrollAnimation>
        <ImageCards cards={motivationCards} />
      </div>
    </div>
  );
};
export default AboutMethod;
