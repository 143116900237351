import moment from 'moment';
import axios from 'axios';

import {
  FETCH_QUESTIONS,
  CREATE_QUESTION,
  DELETE_QUESTION,
  ADD_COMMENT,
  DELETE_QUESTION_ERROR,
  UPDATE_COMMENT,
  SEARCH,
  FETCH_QUESTIONS_BY_PAGE,
} from './types';

moment.locale('he');

/////////////////////////////////////////////////////////////////////////
//discussion section////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export const fetchQuestions = (callback) => async (dispatch) => {
  const res = await axios.get('/api/discussion');
  dispatch({ type: FETCH_QUESTIONS, payload: res.data });
  callback();
};

export const fetchQuestionsByPage = (page) => {
  return { type: FETCH_QUESTIONS_BY_PAGE, payload: page };
};
export const createQuestion = (values, user, callback) => async (dispatch) => {
  const { title, question, name, email } = values;
  const uid = user.uid ? user.uid : '';
  const comments = [
    {
      uid,
      question,
      name,
      email,
      date: moment().format('L'),
      time: moment().format('LTS'),
    },
  ];
  const res = await axios.post('/api/discussion/new', { title, comments });
  dispatch({ type: CREATE_QUESTION, payload: res.data });
  callback();
};

export const addComment = (values, user, id, callback) => async (dispatch) => {
  const { question, name, email } = values;
  //const lastUpdate = moment().format();
  const uid = user.uid ? user.uid : '';
  const comment = {
    uid,
    question,
    name,
    email,
    date: moment().format('L'),
    time: moment().format('LTS'),
  };
  const res = await axios.post('/api/discussion/add', { id, comment });
  dispatch({ type: ADD_COMMENT, payload: res.data });
  callback();
};

export function fetchByUserSearch(search) {
  return {
    type: SEARCH,
    payload: search,
  };
}

export const updateComment = (values, user, id, key, callback) => async (
  dispatch
) => {
  const { question } = values;
  const res = await axios.post('/api/discussion/update', { question, id, key });
  dispatch({ type: UPDATE_COMMENT, payload: res.data });
  callback();
};

export const deleteQuestion = (currentCommentKey, id, question) => async (
  dispatch
) => {
  const currKey = parseInt(currentCommentKey, 10);
  const currArrayLength = question.comments.length;
  if (currArrayLength === 1 && currKey === 0) {
    await axios.delete(`/api/discussion/${id}`);
    dispatch({ type: DELETE_QUESTION, payload: id });
  }

  if (currKey > 0) {
    const res = await axios.post('/api/discussion/remove', { id, currKey });
    dispatch({ type: ADD_COMMENT, payload: res.data });
  }

  return {
    type: DELETE_QUESTION_ERROR,
    payload: null,
  };
};
