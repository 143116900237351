import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { createGame } from "../../actions";

import dictionary from "../../dictionary/dictionary.json";
import gameIdHelp from "../../../assets/create_game_id.png";
import questionMark from "../../../assets/question_mark.png";

const { Labels, Buttons, Message } = dictionary;

const tooltip = <Tooltip id="tooltip">תנו שם לפרוייקט שלכם</Tooltip>;

class CreateGame extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      error: null,
      show: false,
    };
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }
  onSubmit(values) {
    this.props.createGame(values, this.props.user, () => {});
  }

  renderField(field) {
    return (
      <div className="form-group">
        <label>{field.lable}</label>
        <input className="form-control" type="text" {...field.input} />
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.user.displayName != process.env.REACT_APP_ADMIN) {
      return null;
    }
    return (
      <div>
        <form
          className="form-inline"
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          <Field
            lable={Labels.HN.Game_ID}
            name="game_id"
            component={this.renderField}
          />
          <a href="#" onClick={this.handleShow.bind(this)}>
            <img src={questionMark} width={40} height={40} alt="עזרה" />
          </a>
          <Field
            lable={Labels.HN.Game_Name}
            name="game_name"
            component={this.renderField}
          />
          <OverlayTrigger placement="left" overlay={tooltip}>
            <img src={questionMark} width={40} height={40} alt="עזרה" />
          </OverlayTrigger>
          <button type="submit" className="btn btn-success">
            {Buttons.HN.Insert}
          </button>
        </form>
        <div className="error">{this.state.error}</div>
        <div className="error">{Message.HN[this.props.error]}</div>
        <div>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton />
            <img
              src={gameIdHelp}
              width={600}
              height={500}
              alt="הסבר להוספת משחק סקראץ'"
            />
          </Modal>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.game_id) {
    errors.game_id = Message.HN.EmailEmpty;
  }
  if (!values.game_name) {
    errors.game_name = Message.HN.UserNameEmpty;
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    error: state.playground.error,
  };
}

export default reduxForm({
  validate,
  form: "GameNewForm",
})(connect(mapStateToProps, { createGame })(CreateGame));
