import React, { Component } from "react";
import DynamicBlock from "./dynamicBlock";
import SwiperCube from "./swiperCube";

import "./aboutMe.css";

class AboutMe extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="aboutme-container">
          <div className="aboutme-card">
            <div className="aboutme-info">
              {/* <h2>מנהל המיזם - דוד דורה</h2>
              <p>
                נעים מאוד, אני דוד דורה ואני מנהל את המיזם הדור הבא.
                <br />
                במיזם אנו מלמדים ילדים ונוער את יסודות התכנות בדרך חווייתית של
                בניית משחקים ואנימציות.
                <br /> כילד בגיל 9, התאהבתי בעולם התכנות בכך שהתחלתי לתכנת
                אנימציות ומשחקי מחשב, ולאחר שירות צבאי כלוחם וכקצין, חזרתי לעולם
                התכנות וכמו שאומרים זה היה בדיוק כמו לרכב על אופניים, יסודות
                ועולמות התכנות נשארו אצלי בידע ובכך סיימתי את לימודיי במדעי
                המחשב בהצטיינות ולאחר מכן הייתי מתכנת מוביל בחברה מובילה בשוק
                ההייטק ושם קודמתי לתפקידי ניהול שונים. כמה עשרות שנים אחרי,
                החלטתי שאני מעביר את הניסיון והידע שלי הלאה. הקמתי את הדור הבא
                אשר מטרתו להיות מרכז ידע מוביל בתחום החלום שלי הוא שכל ילד יעבור
                את החוויה הזו ואת ההרפתקה של בניית תוכניות במחשב ובכך שיעבירו את
                עולם הדמיון והיצירה שלהם ויגשימו אותו דרך עולם התכנות. <br />
                וכמובן המטרה הסופית היא לגדל את הדור הבא....
              </p> */}
              <DynamicBlock pageID={"about-me"} block={0} />
            </div>
            <div className="cube-container">
              <SwiperCube />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutMe;
