import _ from "lodash";
import {
  FETCH_QUESTIONS,
  CREATE_QUESTION,
  DELETE_QUESTION,
  ADD_COMMENT,
  UPDATE_COMMENT,
  SEARCH,
  FETCH_QUESTIONS_BY_PAGE
} from "../actions/types";

const INITIAL_STATE = {
  questions: {},
  error: null,
  saveAllQuestions: {},
  currentPage: 0,
  numOfPages: 0,
  questionsByPage: {},
  numPerPage: 5
};

export default function(state = INITIAL_STATE, action) {
  let questions = {};
  let saveAllQuestions = {};
  switch (action.type) {
    case FETCH_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        saveAllQuestions: action.payload,
        numOfPages: Math.ceil(_.size(action.payload) / state.numPerPage)
      };
    case UPDATE_COMMENT:
      questions = { ...state.questions, ...action.payload };
      saveAllQuestions = { ...state.saveAllQuestions, ...action.payload };
      const currPageAfterUpdate = fetchByPage(
        questions,
        state.currentPage,
        state.numPerPage
      );
      return {
        ...state,
        questions,
        saveAllQuestions,
        ...currPageAfterUpdate
      };
    case CREATE_QUESTION:
      questions = { ...action.payload, ...state.questions };
      saveAllQuestions = {
        ...action.payload,
        ...state.saveAllQuestions
      };
      const currPageAfterCreateQuestion = fetchByPage(
        saveAllQuestions,
        1,
        state.numPerPage
      );
      return {
        ...state,
        questions,
        saveAllQuestions,
        ...currPageAfterCreateQuestion,
        currentPage: 1,
        numOfPages: Math.ceil(_.size(saveAllQuestions) / state.numPerPage)
      };
    case ADD_COMMENT:
      questions = { ...state.questions, ...action.payload };
      saveAllQuestions = { ...state.saveAllQuestions, ...action.payload };
      const currPageAfterAddComment = fetchByPage(
        questions,
        state.currentPage,
        state.numPerPage
      );
      return {
        ...state,
        questions,
        saveAllQuestions,
        ...currPageAfterAddComment
      };
    case DELETE_QUESTION: {
      const currData = _.omit(state.questions, action.payload);
      const currPageAfterDelete = fetchByPage(
        currData,
        state.currentPage,
        state.numPerPage
      );
      return {
        ...state,
        questions: currData,
        saveAllQuestions: currData,
        ...currPageAfterDelete
      };
    }
    case FETCH_QUESTIONS_BY_PAGE: {
      const currPage = fetchByPage(
        state.saveAllQuestions,
        action.payload,
        state.numPerPage
      );
      return { ...state, ...currPage, currentPage: action.payload };
    }
    case SEARCH: {
      const currData = searchByStr(state.saveAllQuestions, action.payload);
      const currPageAfterSearch = fetchByPage(
        currData.questions,
        1,
        state.numPerPage
      );
      return {
        ...state,
        ...currData,
        ...currPageAfterSearch,
        currentPage: 1,
        numOfPages: Math.ceil(_.size(currData.questions) / state.numPerPage)
      };
    }
    default:
      return state;
  }
}

const searchByStr = (data, search) => {
  if (search === "") {
    return { questions: data, error: null };
  }
  const newState = {};
  Object.keys(data).forEach(key => {
    if (data[key].title.search(search) !== -1) {
      newState[key] = data[key];
    } else {
      const currComments = data[key].comments;
      Object.keys(currComments).forEach(key2 => {
        if (currComments[key2].question.search(search) !== -1) {
          newState[key] = data[key];
          return false;
        }
      });
    }
  });

  if (_.isEmpty(newState)) {
    return { error: "results_not_found" };
  }

  return { questions: newState, error: null };
};

const fetchByPage = (data, page, numPerPage) => {
  let items = {};
  const array = _.toPairsIn(data);
  const last = page * numPerPage;
  const lastItem = array.length < last ? array.length : last;
  const firstItem = last - numPerPage;

  for (let i = firstItem; i < lastItem; i++) {
    items[array[i][0]] = array[i][1];
  }
  return { questionsByPage: items };
};
