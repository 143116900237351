import React from "react";
import MediaQuery from "react-responsive";
import RotateMsg from "../helpers/rotate_msg";
import Header from "../helpers/header";
import { isMobileDevice } from "../../utils/checks";
const { websites } = require("../../utils/student_websites.json");

const renderWebsite = (web) => {
  const title =
    "ברוכים הבאים לאתר של" + " " + web.firstName + " " + web.lastName;
  return (
    <div className="html-viewer">
      <center>
        <Header title={title} back />
        <div>
          <div>
            <h3>{web.title}</h3>
          </div>
          <div className="html-viewer-container">
            <iframe
              className="html-viewer-iframe"
              scrolling="no"
              src={`https://firebasestorage.googleapis.com/v0/b/hadoraba-students.appspot.com/o/${web.fileName}.html?alt=media&token=${web.token}`}
            />
          </div>
        </div>
      </center>
    </div>
  );
};

const HtmlViewerOLD = (props) => {
  //console.log(props.match.params);

  const { classId } = props.match.params;
  const { id } = props.match.params;
  const web = websites[classId]["StudentWebsites"][id];
  if (!isMobileDevice()) {
    return renderWebsite(web);
  }
  return (
    <div>
      <MediaQuery orientation="portrait">
        <RotateMsg />
      </MediaQuery>
      <MediaQuery orientation="landscape">{renderWebsite(web)}</MediaQuery>
    </div>
  );
};

export default HtmlViewerOLD;
