import React, { useState, useEffect } from "react";
import _ from "lodash";
import { databaseFire } from "../../actions/firebase-blog";
import "./Links.css";

const Links = (props) => {
  const { id } = props;
  const [links, setLinks] = useState([]);
  useEffect(() => {
    const search = async () => {
      let data = {};
      const querySnapshot = await databaseFire
        .collection("links")
        .where("library", "==", "course-utils")
        .where("title", "==", id)
        // .orderBy("index")
        .get();
      querySnapshot.forEach((doc) => {
        data[doc.id] = doc.data();
      });
      console.log("data", data);
      setLinks(data);
      window.scrollTo(0, 0);
    };

    search();
  }, []);

  const renderLinks = () => {
    const groupedLinks = _.groupBy(links, "subtitle");

    return Object.keys(groupedLinks).map((subtitle) => {
      const sortedLinks = _.sortBy(groupedLinks[subtitle], ["index"]);
      return (
        <div key={subtitle} className="paragraph-links-container">
          <h2 className="links-title">{subtitle}</h2>
          <div className="paragraph-links">
            {sortedLinks.map((link, key) => {
              return (
                <div>
                  <a
                    className="link-box"
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.primaryURL}
                  >
                    <img
                      src={
                        link.buttonImageURL
                          ? link.buttonImageURL
                          : "/images/icons/link_icon.webp"
                      }
                    />
                    <label>{link.linkName}</label>
                    <p>{link.details}</p>
                    <button className="btn btn-success">{link.linkMD1}</button>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="links-container">
      <div>{renderLinks()}</div>
    </div>
  );
};
export default Links;
