import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Links from "../UI/Links";

import { SEO, StudentsArea } from "../../dictionary/dictionary.json";

const { StudentsT } = SEO;

const LibraryDynamicPage = (props) => {
  const { id } = props.match.params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderPage = () => {
    return (
      <div style={{ margin: "15px" }}>
        <h1 style={{ textAlign: "center", color: "#3ad7a6" }}>
          {StudentsArea[id]}
        </h1>
        <Links id={id} />
      </div>
    );
  };
  return (
    <div>
      <Helmet>
        <title>{StudentsT.title}</title>
        <meta name="description" content={StudentsT.desc} />
        <meta name="title" content={StudentsT.title} />
      </Helmet>
      <div>{renderPage()}</div>
    </div>
  );
};

export default LibraryDynamicPage;
