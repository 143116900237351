import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { logIn } from "../../actions";

import dictionary from "../../dictionary/dictionary.json";

const { Labels, Buttons, Message } = dictionary;

class UserLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  onSubmit(values) {
    this.props.logIn(values, () => {
      this.props.handleClose();
    });
  }

  renderField(field) {
    const {
      meta: { touched, error },
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={field.lable}>{field.lable}</label>
        <input
          className="form-control"
          type={field.type}
          title={field.title}
          {...field.input}
        />
        <div className="text-help" style={{ color: "red" }}>
          {touched ? error : ""}
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, handleClose } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit.bind(this))} noValidate>
        <Field
          lable={Labels.HN.EmailNew}
          name="user_email"
          component={this.renderField}
          type="email"
        />
        <Field
          lable={Labels.HN.Password}
          name="user_password"
          component={this.renderField}
          type="password"
        />
        <button type="submit" className="btn btn-primary ModalButton">
          {Buttons.HN.Sign_In}
        </button>
        <a
          onClick={() => {
            handleClose();
          }}
          className="btn btn-danger ModalButton"
        >
          {Buttons.HN.Cancel}
        </a>

        <a href={"/"}>שחזור סיסמא</a>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.user_email) {
    errors.user_email = Message.HN.EmailEmpty;
  }
  if (!values.user_password) {
    errors.user_password = Message.HN.PasswordEmpty;
  }

  return errors;
}

export default reduxForm({
  validate,
  form: "LogIn",
})(connect(null, { logIn })(UserLogin));
