import React from "react";

import ScrollAnimation from "./ScrollAnimation";
import "./imageCards.css";

const ImageCards = (props) => {
  return (
    <div className="imageCardsContainer">
      {props.cards.map((card, index) => {
        return (
          <div className="imageCard">
            <ScrollAnimation animationType={"fadeInUp"}>
              <img src={card.img} />
              <h5>{card.title}</h5>
            </ScrollAnimation>
          </div>
        );
      })}
    </div>
  );
};
export default ImageCards;
