import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { register } from "../../actions";

import dictionary from "../../dictionary/dictionary.json";

const { Labels, Buttons, Message } = dictionary;

const formStyle = {
  marginRight: "40px",
};

class UserRegister extends Component {
  onSubmit(values) {
    this.props.register(values, () => {
      this.props.handleClose();
    });
  }

  renderField(field) {
    const {
      meta: { touched, error },
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={field.lable}>{field.lable}</label>
        <input
          className="form-control"
          type={field.type}
          {...field.input}
          autoComplete="off"
        />
        <div className="text-help" style={{ color: "red" }}>
          {touched ? error : ""}
        </div>
      </div>
    );
  }

  renderCBField(field) {
    return (
      <div>
        <input type={field.type} {...field.input} />
        <label htmlFor={field.lable}>{field.lable}</label>
      </div>
    );
  }

  renderStudentFields() {
    return (
      <div>
        <label htmlFor={"מרכז"}>{"מרכז"}</label>
        <Field name="location" component="select">
          <option />
          <option value="regaim">רגעים</option>
          <option value="leader">לידר</option>
        </Field>
        <label htmlFor={"קבוצה"}>{"קבוצה"}</label>
        <Field lable={"קבוצה"} name="group" component="select">
          <option />
          <option value="1">1</option>
          <option value="2">2</option>
        </Field>

        <Field
          lable={"קוד גישה"}
          name="group_code"
          component={this.renderField}
          type="password"
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, handleClose, isStudent } = this.props;
    //console.log(this.props);

    return (
      <form onSubmit={handleSubmit(this.onSubmit.bind(this))} noValidate>
        <Field
          lable={Labels.HN.Email}
          name="user_email"
          component={this.renderField}
          type="email"
        />
        <Field
          lable={Labels.HN.User_Name}
          name="user_name"
          component={this.renderField}
        />

        <Field
          lable={Labels.HN.Password}
          name="user_password"
          component={this.renderField}
          type="password"
        />

        <Field
          lable={Labels.HN.Password_Confirm}
          name="user_password_conf"
          component={this.renderField}
          type="password"
        />

        <Field
          lable="תלמיד בקורס"
          name="student"
          id="student"
          component={this.renderCBField}
          type="checkbox"
        />

        {isStudent && this.renderStudentFields()}

        <button type="submit" className="btn btn-primary">
          {Buttons.HN.Register}
        </button>
        <a
          onClick={() => {
            handleClose();
          }}
          className="btn btn-danger"
        >
          {Buttons.HN.Cancel}
        </a>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.user_email) {
    errors.user_email = Message.HN.EmailEmpty;
  }
  if (!values.user_name) {
    errors.user_name = Message.HN.UserNameEmpty;
  }
  if (!values.user_password) {
    errors.user_password = Message.HN.PasswordEmpty;
  }
  if (!values.user_password_conf) {
    errors.user_password_conf = Message.HN.PasswordConfEmpty;
  }
  if (values.user_password !== values.user_password_conf) {
    errors.user_password_conf = Message.HN.PasswordConfNotEqual;
  }

  return errors;
}

UserRegister = reduxForm({
  validate,
  form: "RegisterForm",
})(UserRegister);

const selector = formValueSelector("RegisterForm");

UserRegister = connect(
  (state) => {
    const isStudent = selector(state, "student");
    return { isStudent };
  },
  { register }
)(UserRegister);

export default UserRegister;
/*
export default reduxForm({
  validate,
  form: "RegisterForm"
})(
  connect(
    null,
    { register }
  )(UserRegister)
);
*/
