import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import RotateMsg from "../helpers/rotate_msg";
import { isMobileDevice, checkIfHebrew } from "../../utils/checks";
import { Labels } from "../../dictionary/dictionary.json";
import axios from "axios";

const renderGame = (id) => {
  const [currInstructions, setCurrInstructions] = useState([]);
  const [pageStatus, setpageStatus] = useState("loading");
  const [textDirection, setTextDirection] = useState("rtl");

  useEffect(() => {
    const search = async () => {
      try {
        const res = await axios.get(`/api/games-instructions/${id}`);

        if (res.data.instructions) {
          setCurrInstructions(res.data.instructions);
          if (checkIfHebrew(res.data.instructions)) {
            setTextDirection("rtl");
          } else {
            setTextDirection("ltr");
          }
          setpageStatus("pageFound");
        } else {
          setpageStatus("pageNotFound");
          setCurrInstructions([]);
        }
      } catch {
        setpageStatus("pageNotFound");
        setCurrInstructions([]);
      }
    };

    search();
  }, [id]);

  const renderInstructions = () => {
    if (pageStatus === "loading") {
      return "";
    } else if (pageStatus === "pageFound") {
      if (currInstructions) {
        return <React.Fragment>{currInstructions}</React.Fragment>;
      }
    } else {
      return <div>ERROR</div>;
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Link to="/playground" className="btn btn-primary">
        <span className="glyphicon glyphicon-circle-arrow-right" />{" "}
        {Labels.HN.Back_To_Playground}
      </Link>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://scratch.mit.edu/projects/${id}`}
        className="btn btn-warning"
      >
        {Labels.HN.Go_To_Scratch_Website}
      </a>
      <div className="show-game-container">
        <iframe
          scrolling="no"
          className="show-game-iframe"
          title={id}
          src={`//scratch.mit.edu/projects/${id}/embed`}
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <div className="instruction-container">
        <h2>הוראות</h2>
        <div
          dir={textDirection}
          style={
            textDirection === "ltr"
              ? { textAlign: "left" }
              : { textAlign: "right" }
          }
          className="instruction-details"
        >
          {renderInstructions()}
        </div>
      </div>
    </div>
  );
};

const ShowGame = (props) => {
  const { id } = props.match.params;

  if (!isMobileDevice()) {
    return renderGame(id);
  }

  return (
    <div>
      <MediaQuery orientation="portrait">
        <RotateMsg />
      </MediaQuery>
      <MediaQuery orientation="landscape">{renderGame(id)}</MediaQuery>
    </div>
  );
};

export default ShowGame;
