import React from "react";
import "animate.css"; // Import animate.css styles
import { useInView } from "react-intersection-observer";
import "./ScrollAnimation.css"; // Import custom CSS for ScrollAnimation

const ScrollAnimation = ({ animationType, children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      className={`animation-container-scrollAnimation ${
        inView ? `animate__animated animate__${animationType}` : ""
      }`}
      ref={ref}
    >
      <div
        className={`content-scrollAnimation ${
          inView ? "animate-opacity-in-scrollAnimation" : "animate-opacity-out"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollAnimation;
