import React from 'react';
import MediaQuery from 'react-responsive';
import RotateMsg from '../helpers/rotate_msg';
import { isMobileDevice } from '../../utils/checks';

const renderBlocks = (category) => {
  return (
    <div className="show-game-container">
      <iframe
        scrolling="no"
        className="show-game-iframe"
        src={`https://wiki-scratch.firebaseapp.com/src/${category}/${category}.html`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

const Blocks = (props) => {
  const { category } = props.match.params;
  if (!isMobileDevice()) {
    return renderBlocks(category);
  }
  return (
    <div>
      <MediaQuery orientation="portrait">
        <RotateMsg />
      </MediaQuery>
      <MediaQuery orientation="landscape">{renderBlocks(category)}</MediaQuery>
    </div>
  );
};

export default Blocks;
