import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import RotateMsg from "../helpers/rotate_msg";
import Header from "../helpers/header";
import { isMobileDevice } from "../../utils/checks";
import { databaseFire } from "../../actions/firebase-blog";

const HtmlViewer = (props) => {
  //console.log(props.match.params);

  const { id } = props.match.params;
  const [web, setWeb] = useState([]);
  useEffect(() => {
    const search = async () => {
      const data = {};
      try {
        console.log("id", id);
        const doc = await databaseFire.collection("files").doc(id).get();
        if (doc.exists) {
          setWeb(doc.data());
          console.log(doc);
        } else {
          console.log("No such document!");
        }
      } catch {
        console.log("fetch faild");
      }
    };

    search();
    window.scrollTo(0, 0);
  }, []);

  const renderWebsite = (web) => {
    console.log("web2", web);
    const title = "ברוכים הבאים לאתר של" + " " + web.fileMD2;
    return (
      <div className="html-viewer">
        <center>
          <Header title={title} back />
          <div>
            <div>
              <h3>{web.fileMD3}</h3>
            </div>
            <div className="html-viewer-container">
              <iframe
                className="html-viewer-iframe"
                scrolling="no"
                src={web.fileURL}
              />
            </div>
          </div>
        </center>
      </div>
    );
  };

  if (!isMobileDevice()) {
    return <div>{renderWebsite(web)}</div>;
  }
  return (
    <div>
      <MediaQuery orientation="portrait">
        <RotateMsg />
      </MediaQuery>
      <MediaQuery orientation="landscape">{renderWebsite(web)}</MediaQuery>
    </div>
  );
};

export default HtmlViewer;
