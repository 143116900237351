import React from "react";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>תודה רבה שרכשתם</h1>
      <h1>בחנות של הדור הבא</h1>
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          padding: "5px 5px",
          margin: "0 400px",
          borderRadius: "15px",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "white",
          }}
        >
          <h3>חזור לדף הבית </h3>
        </Link>
      </div>
      <img src={"/images/thank-you.png"} width="300" />
    </div>
  );
};
export default ThankYouPage;
