import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import "./swiperCube.css";
import { EffectCube, Autoplay } from "swiper";

const aboutMePhotos = [
  "david-dora.jpeg",
  "david-dora-young.jpeg",
  "david-teach.png",
  "david-center.jpg",
];
//const aboutMePhotos = ["david-dora.jpeg"];

const SwiperCube = (props) => {
  const renderSlides = () => {
    return aboutMePhotos.map((item, index) => {
      //console.log(item);
      return (
        <SwiperSlide key={index}>
          <div className="david-imgbx">
            <img src={`images/${item}`} alt="David-Dora" />
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <Swiper
      effect={"cube"}
      grabCursor={true}
      loop
      speed={4000}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        reverseDirection: false,
        pauseOnMouseEnter: true,
      }}
      modules={[EffectCube, Autoplay]}
      className="SwiperCube"
    >
      {renderSlides()}
    </Swiper>
  );
};
export default SwiperCube;
