import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";

import _ from "lodash";

import "./blog.css";
import { databaseFire } from "../../actions/firebase-blog";

const Blog = (props) => {
  const [posts, setPosts] = useState([]);
  const { category } = props.match.params;

  useEffect(() => {
    window.scrollTo(0, 0);
    const search = async () => {
      const data = {};
      const querySnapshot = await databaseFire.collection("blog-posts").get();
      querySnapshot.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      setPosts(data);
    };

    search();
  }, []);

  const renderPosts = _.map(posts, (post, key) => {
    console.log(post);
    return (
      <div key={key} className="blog-container">
        <Link to={`/blog/${key}`}>
          <div className="blog-box-image">{renderHTML(post.blogImg)}</div>
          <div className="blog-content">
            <h4>{post.blogTitle}</h4>
          </div>

          <button className="btn btn-success">קראו עוד</button>
        </Link>
      </div>
    );
  });

  return (
    <div style={{ textAlign: "center" }}>
      <h3 style={{ color: "white" }}>
        {" "}
        לעיונכם מידע ומאמרים לשיפור יכולות הילדים בלימודי התכנות
      </h3>
      <div className="blogs-container">{renderPosts}</div>
    </div>
  );
};
export default Blog;
