import axios from "axios";
import { auth } from "./firebase";

import {
  LOG_IN,
  LOG_OUT,
  REGISTER,
  LOG_IN_ERROR,
  REGISTER_ERROR,
  CLEAR_ERROR,
} from "./types";

/////////////////////////////////////////////////////////////////////////
//auth section////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export const logInConfirm = (user) => {
  return {
    type: LOG_IN,
    payload: user,
  };
};

export const logIn = (values, callback) => async (dispatch) => {
  if (
    values.user_email === process.env.REACT_APP_STUDENT_CURR_PASS &&
    values.user_password === process.env.REACT_APP_STUDENT_CURR_PASS
  ) {
    values.user_email = process.env.REACT_APP_STUDENT_USER;
    values.user_password = process.env.REACT_APP_STUDENT_PASS;
  }
  try {
    const res = await auth.signInWithEmailAndPassword(
      values.user_email,
      values.user_password
    );
    dispatch({ type: LOG_IN, payload: res.user });
    callback();
  } catch (error) {
    dispatch({ type: LOG_IN_ERROR, payload: error });
  }
};

export const register = (values, callback) => async (dispatch) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(
      values.user_email,
      values.user_password
    );
    await res.user.updateProfile({ displayName: values.user_name });
    const userDetails = {
      id: res.user.uid,
      display_name: res.user.displayName,
      email: res.user.email,
      location: values.location,
      group: values.group,
      group_code: values.group_code,
    };
    //console.log(userDetails);

    await axios.post("/api/users/create", userDetails);
    await dispatch({ type: REGISTER, payload: res.user });
    callback();
  } catch (error) {
    dispatch({ type: REGISTER_ERROR, payload: error });
  }
};

export function signOutConfirm() {
  return {
    type: LOG_OUT,
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export { auth };
