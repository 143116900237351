import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import Footer from "./footer";
import "./template.css";

// import Contact from "./contact";

// import img from "../../../assets/hadoraba.png";
import whatsapp from "../../../assets/whatsapp-ico.png";

import dictionary from "../../dictionary/dictionary.json";

const tooltip = <Tooltip id="wa-tooltip">צרו קשר עכשיו</Tooltip>;

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <header>
          <Navbar />
        </header>
        <main className="main-container">
          <div id="main">{this.props.children}</div>
        </main>

        <CookieConsent
          location="bottom"
          disableStyles
          buttonText={"המדיניות מקובלת עלי"}
          expires={365}
          containerClasses="cookie"
          contentClasses="cookie-text"
          buttonClasses="cookie-button"
        >
          הי חברים האתר שלנו משתמש בעוגיות(Cookies) לפרטים נוספים כנסו{" "}
          <Link
            to="/article/policy"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            לתקנון האתר
          </Link>
        </CookieConsent>
        <Footer />
        <a
          target="_blank"
          rel="noopener noreferrer"
          title="שלחו לנו Whatsapp"
          href="https://wa.me/972554320014"
        >
          <OverlayTrigger placement="left" overlay={tooltip}>
            <img
              src={whatsapp}
              alt="הדור הבא וואטסאפ whatsapp"
              style={{
                position: "sticky",
                bottom: 30,
                right: 30,
                padding: "5px",
                width: "80px",
                height: "80px",
              }}
            />
          </OverlayTrigger>
        </a>
      </React.Fragment>
    );
  }
}

export default Layout;
