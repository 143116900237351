import React from "react";
import { Link } from "react-router-dom";
import cry from "../../../assets/page_not_found.png";

const PageNotFound = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>שגיאה 404</h1>
      <h1>העמוד לא נמצא באתר הדור הבא</h1>
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          padding: "5px 5px",
          margin: "0 400px",
          borderRadius: "15px",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "white",
          }}
        >
          <h3>חזור לדף הבית </h3>
        </Link>
      </div>
      <img src={cry} className="image-notfound" alt="404-cry" />
    </div>
  );
};
export default PageNotFound;
