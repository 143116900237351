import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import GameReducer from "./reducer_game";
import UserReducer from "./reducer_user";
import QuestionReducer from "./reducer_questions";
import ContactUsReducer from "./reducer_contact_us";

const rootReducer = combineReducers({
  playground: GameReducer,
  form: formReducer,
  auth: UserReducer,
  questions: QuestionReducer,
  toastr: toastrReducer,
  contactUs: ContactUsReducer,
});

export default rootReducer;
