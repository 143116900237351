import axios from "axios";
import {
  FETCH_GAMES,
  FETCH_GAMES_ERROR,
  CREATE_GAME,
  CREATE_GAME_ERROR,
  DELETE_GAME,
  DELETE_GAME_ERROR,
  FETCH_GAMES_BY_USER,
  FETCH_GAMES_BY_USER_ERROR,
} from "./types";

//playground section
export const fetchGames = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/games");

    dispatch({ type: FETCH_GAMES, payload: res.data });
  } catch (error) {
    dispatch({ type: FETCH_GAMES_ERROR, payload: error.response.data });
  }
};

export const fetchGamesForUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/games/${id}`);
    dispatch({ type: FETCH_GAMES_BY_USER, payload: res.data });
  } catch (error) {
    dispatch({ type: FETCH_GAMES_BY_USER_ERROR, payload: error.response.data });
  }
};

export const createGame = (values, user, callback) => async (dispatch) => {
  const { game_name, game_id } = values;
  const uid = user.uid ? user.uid : "";
  const currGame = { game_name, game_id, uid };
  try {
    const res = await axios.post("/api/games/create", currGame);
    dispatch({ type: CREATE_GAME, payload: res.data });
  } catch (error) {
    dispatch({ type: CREATE_GAME_ERROR, payload: error.response.data });
  }
  callback();
};

export const deleteGame = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/games/delete/${id}`);
    dispatch({ type: DELETE_GAME, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_GAME_ERROR, payload: error.response.data });
  }
};
