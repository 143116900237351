import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import _ from "lodash";
import Header from "../helpers/header";
// import webImg from "../../../assets/websiteImg.jpg";
import { databaseFire } from "../../actions/firebase-blog";

const StudentsWebs = (props) => {
  const { id, year } = props.match.params;

  const [students, setStudents] = useState([]);
  const title = "מתכנתי הדור הבא של " + id;
  useEffect(() => {
    const search = async () => {
      let data = {};
      const querySnapshot = await databaseFire
        .collection("files")
        .where("fileMD1", "==", id)
        .orderBy("fileMD2")
        .get();
      querySnapshot.forEach((doc) => {
        data[doc.id] = doc.data();
      });
      console.log("data", data);
      setStudents(data);
      window.scrollTo(0, 0);
    };

    search();
  }, []);
  const renderStudents = () => {
    return _.map(students, (student, key) => {
      return (
        <div className="student-container" key={key}>
          <Card style={{ backgroundColor: "black", width: "18rem" }}>
            <Card.Img
              variant="top"
              src={"/images/website-final.webp"}
              alt={student.fileMD2}
            />
            <Card.Body>
              <Card.Title>{student.fileMD2}</Card.Title>
              <Card.Text>{student.fileMD3}</Card.Text>
              <Link
                className="btn btn-primary"
                to={`/library/classes/classesnew/${year}/${id}/${key}`}
              >
                כנס לקישור
              </Link>
            </Card.Body>
          </Card>
        </div>
      );
    });
  };

  return (
    <div className="library-container2">
      <Header title={title} back />
      <div>{renderStudents()}</div>
    </div>
  );
};

export default StudentsWebs;
