import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Links from "../UI/Links";

import { SEO, StudentsArea } from "../../dictionary/dictionary.json";

const { StudentsT } = SEO;

const StudentDynamicPage = (props) => {
  const { id } = props.match.params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderPage = () => {
    if (props.user.displayName !== process.env.REACT_APP_STUDENTS) {
      return (
        <div>
          <h1>הכניסה לתלמידים רשומים בלבד</h1>
        </div>
      );
    }
    return (
      <div style={{ margin: "15px" }}>
        <h1 style={{ textAlign: "center" }}>{StudentsArea[id]}</h1>
        <Links id={id} />
      </div>
    );
  };
  return (
    <div>
      <Helmet>
        <title>{StudentsT.title}</title>
        <meta name="description" content={StudentsT.desc} />
        <meta name="title" content={StudentsT.title} />
      </Helmet>
      <div>{renderPage()}</div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(StudentDynamicPage);
