import axios from "axios";

import { CREATE_CONTACT_US, CREATE_CONTACT_US_ERROR } from "./types";

/* old version with nodemailer*/
export const createNewContact = (values, callback) => async (dispatch) => {
  try {
    values["create_date"] = new Date().toLocaleString();
    const res = await axios.post("/api/contactus", values);

    dispatch({ type: CREATE_CONTACT_US, payload: res.data });
  } catch (error) {
    dispatch({ type: CREATE_CONTACT_US_ERROR, payload: error });
  }
  callback();
};
