import React, { useEffect, useRef } from "react";
import "./videoBackground.css";

const VideoBackground = (props) => {
  const videoRef = useRef(null);
  useEffect(() => {
    const videoElement = videoRef.current;

    // Autoplay the video when the component mounts
    videoElement.play();

    return () => {
      // Pause and clean up the video when the component unmounts
      videoElement.pause();
    };
  }, []);
  return (
    <div className="video-container">
      <video ref={videoRef} loop muted playsInline>
        <source src={props.videoSrc} type="video/mp4" />
        האתר שלך לא תומך בסרטוני וידאו
      </video>
      <div className="text-overlay">
        <h1>{props.title}</h1>
        <p>{props.subtitle}</p>
      </div>
      {/* <div className="text-overlay-type typewriter">
        <p>
          {" "}
          "התכנות הוא המיומנות הבסיסית של המאה ה-21. זהו לא רק כלי לפתירת בעיות
          טכנולוגיות, אלא גם כלי לפתירת בעיות בכל תחום בחיים וכלי המאפשר לנו
          לקדם את יכולת החשיבה היצירתית והשותפות החברתית שלנו"
          <br />
          <span
            style={{
              color: "#3ad7a6",
              fontWeight: "bold",
              textShadow: "5px 5px 5px black",
            }}
          >
            מארק צוקרברג, מייסד פייסבוק.
          </span>
        </p>

        
      </div> */}
    </div>
  );
};
export default VideoBackground;
