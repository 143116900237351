import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../helpers/header";

import ContentCards from "../UI/contentCards";

import { scratcherBook } from "../../dictionary/scratcherbook.json";
const { part1, part2, part3, partEzer } = scratcherBook;

import { MenuItems, SEO } from "../../dictionary/dictionary.json";

const { StoreT } = SEO;

import "./store.css";

import { LOG_IN } from "../../actions/types";

const Store = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div style={{ marginBottom: "20px" }}>
          <Header title="תוכן הערכה" />
        </div>
        <div>
          <img src="images/store/ezer-back.png" />
          <img src="images/store/ezer-front.png" />
          <div>
            {/* <h1 style={{ textAlign: "center" }}>תוכן הערכה</h1> */}
            <ContentCards cards={partEzer} />
          </div>
        </div>
        <div>
          <img src="images/store/1-back.png" />
          <img src="images/store/1-front.png" />
          <div>
            <ContentCards cards={part1} />
          </div>
        </div>

        <div>
          <img src="images/store/2-back.png" />
          <img src="images/store/2-front.png" />
          <div>
            {/* <h1 style={{ textAlign: "center" }}>תוכן הערכה</h1> */}
            <ContentCards cards={part2} />
          </div>
        </div>

        <div>
          <img src="images/store/3-back.png" />
          <img src="images/store/3-front.png" />
          <div>
            {/* <h1 style={{ textAlign: "center" }}>תוכן הערכה</h1> */}
            <ContentCards cards={part3} />
          </div>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    if (process.env.REACT_APP_STORE_FLAG === "N") {
      return (
        <div>
          <h1>העמוד בבנייה</h1>
        </div>
      );
    }
    return (
      <React.Fragment>
        {/*COVER */}
        <div className="store-cover">
          <div className="store-image-cover">
            <div className="book-example">
              <img src="images/store/scratch-book-title.png" />
              <img src="images/store/books-demo.png" />
            </div>
            {/* <div className="shopingil-gif">
              <img src="images/store/ShopingIL_sticker_02.gif" />
            </div> */}
            <div className="scratch-hacker">
              <img src="images/store/hacker-scratch.png" />
            </div>
          </div>
        </div>

        <div className="contentShop">
          <h2 style={{ textAlign: "center" }}>המדריך לסקראצ'רים</h2>

          <h3>מה נלמד בערכה?</h3>
          <p>
            אנו נלמד את שפת התוכנה סקראץ' (Scratch)
            <br />
            שפה זו פותחה באם־איי־טי (MIT) המכון הטכנולוגי של מסצ'וסטס המפורסם
            בארצות הברית. אנו נלמד את מושגי היסוד של מדעי המחשב ונוכל להמציא
            משחקים וליצור אותם.
          </p>
          <h3>למי הערכה מתאימה?</h3>
          <p>
            הערכה מתאימה לכל הילדות והילדים. מתחילים, ללא רקע בתכנוּת, וגם אלה
            שמכירים את התוכנה ורוצים להרחיב את הידע ולשפר את ההבנה יוכלו, בעזרת
            הספרים, לפתח תוכניות, ליצור סרטונים ולתכנת משחקים מרהיבים. הערכה
            מעודדת חשיבה מִחשוּבית, מלמדת את יסודות התכנות ומפתחת דמיון
            ויצירתיות ביצירת תוכניות ומשחקי מחשב מהנים.
          </p>
          <h3>איך להשתמש בערכה?</h3>
          <p>
            הלימוד על פי הערכה ייעשה בשלושה שלבים, שהם הצעדים הראשונים שלנו
            בשפות התכנות.
            <br />
            <ul>
              <li>
                בשלב הראשון נכיר את עולם המחשבים, נלמד את המבוא לשפת הסקראץ’
                ונבנה את התוכניות הראשונות שלנו.
              </li>
              <li>בשלב השני נלמד לתכנת אנימציה ודמויות.</li>
              <li>
                בשלב השלישי נלמד לתכנת תוכניות מורכבות של משחקי מחשב ואנימציות.
              </li>
            </ul>
          </p>
          <div>
            <h2 style={{ fontFamily: "sans-serif", textAlign: "center" }}>
              מחיר מיוחד של 250 שקלים כולל משלוח
            </h2>
          </div>
          <div className="shop-buttons">
            <div className="storeContainerButton">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://dor.page.link/paymant-page"}
                className="btn shopButton btn-shake-animate"
              >
                לרכישת הערכה
              </a>
            </div>

            <div className="storeContainerButton">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://dor.page.link/scratcher-guide-demo"}
                className="btn readDemoButton btn-shake-animate"
              >
                פרקים לקריאה
              </a>
            </div>
          </div>
        </div>

        {renderContent()}

        <div>
          <h2 style={{ fontFamily: "sans-serif", textAlign: "center" }}>
            מחיר מיוחד של 250 שקלים כולל משלוח
          </h2>
        </div>
        <div className="shop-buttons">
          <div className="storeContainerButton">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://dor.page.link/paymant-page"}
              className="btn shopButton btn-shake-animate"
            >
              לרכישת הערכה
            </a>
          </div>
        </div>

        {/* <div>
          <div className="store-item">
            <img src="/images/scratch-box-cover.jpg" alt="scratch-book" />
            <h2>ספר דיגיטאלי</h2>
            <h3>מחיר 99 ש"ח</h3>
            <div>
              <button className="btn-success">לפרטים נוספים</button>
              <button className="btn-success">לרכישה עכשיו</button>
            </div>
          </div>
          <div className="store-item">
            <img src="/images/scratch-box-cover.jpg" alt="scratch-book" />
            <h2>ערכה מודפסת</h2>
            <h3>מחיר 199 ש"ח</h3>
            <div>
              <button className="btn-success">לפרטים נוספים</button>
              <button className="btn-success">לרכישה עכשיו</button>
            </div>
          </div>
          <div className="store-item">
            <img src="/images/scratch-box-cover.jpg" alt="scratch-book" />
            <h2>קורס דיגטאלי</h2>
            <h3>מחיר 550 ש"ח</h3>
            <div>
              <button className="btn-success">לפרטים נוספים</button>
              <button className="btn-success">לרכישה עכשיו</button>
            </div>
          </div>
          <div className="store-item">
            <img src="/images/scratch-box-cover.jpg" alt="scratch-book" />
            <h2>הערכה המושלמת</h2>
            <h3>מחיר 650 ש"ח</h3>
            <div>
              <button className="btn-success">לפרטים נוספים</button>
              <button className="btn-success">לרכישה עכשיו</button>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  };
  return (
    <div>
      <Helmet>
        <title>{StoreT.title}</title>
        <meta name="description" content={StoreT.desc} />
        <meta name="title" content={StoreT.title} />
      </Helmet>
      <div>{renderPage()}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(Store);
