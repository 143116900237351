export const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const checkIfHebrew = (in_text) => {
  const regex = /[א-ת]/g;
  const found = in_text.match(regex);
  if (found != null && found.length > 0) {
    return true;
  } else {
    return false;
  }
};
