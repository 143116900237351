import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles

import "./swiperCardsPartners.css";
import { Autoplay } from "swiper";

const SwiperCardsPartners = (props) => {
  const renderSlides = () => {
    return props.data.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className="imgBx">
            <img src={`images/partners/${item}`} alt="partner" />
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <div>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        loop
        slidesPerView={5}
        spaceBetween={100}
        speed={1000}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          reverseDirection: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
        className="PartnersSwiper"
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};
export default SwiperCardsPartners;
