export const FETCH_GAMES = "fetch_games";
export const FETCH_GAMES_BY_USER = "fetch_games_by_user";
export const CREATE_GAME = "create_game";
export const DELETE_GAME = "delete_game";
export const FETCH_GAMES_ERROR = "fetch_games_error";
export const FETCH_GAMES_BY_USER_ERROR = "fetch_games_by_user_error";
export const CREATE_GAME_ERROR = "create_game_error";
export const DELETE_GAME_ERROR = "delete_game_error";

export const FETCH_QUESTIONS = "fetch_questions";
export const CREATE_QUESTION = "create_question";
export const DELETE_QUESTION = "delete_question";
export const DELETE_QUESTION_ERROR = "delete_question_error";
export const ADD_COMMENT = "add_comment";
export const FETCH_COMMENTS = "fetch_comments";
export const UPDATE_COMMENT = "update_comments";
export const SEARCH = "search";
export const FETCH_QUESTIONS_BY_PAGE = "fetch_questions_by_page";

export const LOG_IN = "log_in";
export const LOG_IN_CONFIRM = "log_in_confirm";
export const LOG_OUT = "log_out";
export const REGISTER = "register";
export const CHECK_AUTH = "check_auth";
export const LOG_IN_ERROR = "log_in_error";
export const REGISTER_ERROR = "register_error";
export const CLEAR_ERROR = "clear_error";

export const CREATE_CONTACT_US = "create_contact_us";
export const CREATE_CONTACT_US_ERROR = "create_contact_us_error";

// export const CREATE_PAYMENT = "create_payment";
// export const CREATE_PAYMENT_ERROR = "create_payment_error";
