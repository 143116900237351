import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Header from "../helpers/header";

const { websites } = require("../../utils/student_websites.json");

class ClassesOLD extends Component {
  renderWebs() {
    return _.map(websites, (web, key) => {
      return (
        <Link to={`/library/classes/classesold/${key}`} className="button3">
          <span>{web.Name}</span>
        </Link>
      );
    });
  }
  render() {
    return (
      <div className="library-container2">
        <Header title="קבוצות לפני 2023" back />
        <div>{this.renderWebs()}</div>
      </div>
    );
  }
}

export default ClassesOLD;
