import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AboutMethod from "../UI/AboutMethod";
import CardsSection from "../UI/cardsSection";
import SwiperCardsVideos from "../UI/swiperCardsVideos";
import Contact from "../UI/contact";
import ImageBackground from "../UI/imageBackground";
import ScrollAnimation from "../UI/ScrollAnimation";

import {
  SEO,
  SchoolCoursesCardSectionData,
  Videos,
} from "../../dictionary/dictionary.json";
const { ActivitesSchoolsT } = SEO;

import "./activities.css";

const ActivitesSchools = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const creatButton = () => {
    return (
      <div style={{ marginBottom: "20px" }}>
        <button
          className="btnCallToAction"
          onClick={() => scrollToSection("register")}
        >
          לתיאום פגישה לתוכנית מותאמת עבור בית ספרכם
        </button>
      </div>
    );
  };

  const content = () => {
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("register")}
          >
            לתיאום פגישה לתוכנית מותאמת עבור בית ספרכם
          </button>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("courses")}
          >
            לתוכניות הלימוד
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{ActivitesSchoolsT.title}</title>
        <meta name="description" content={ActivitesSchoolsT.desc} />
        <meta name="title" content={ActivitesSchoolsT.title} />
        <meta name="keywords" content={ActivitesSchoolsT.tags} />
      </Helmet>

      <div className="activitiesContainer">
        <ImageBackground
          title={"תוכניות הלימוד של הדור הבא"}
          subtitle={"לבתי ספר יסודיים וחטיבות ביניים"}
          content={content}
          desktopSrc="/images/home/computer_class_in_Elementary_School_lg.webp"
          mobileSrc="/images/home/computer_class_in_Elementary_School.webp"
        />
        {creatButton()}
        <div id="courses">
          <CardsSection
            title={SchoolCoursesCardSectionData.title}
            cardsData={SchoolCoursesCardSectionData.cardsData}
          />
        </div>

        {creatButton()}
        <ScrollAnimation animationType={"backInLeft"}>
          <div className="GoodToKnow">
            <h1> מדריכי הדור הבא</h1>
            <p style={{ textAlign: "right", fontSize: "20px" }}>
              הקורסים והסדנאות שלנו מובלים על ידי מדריכים שאוהבים ומתחברים לעולם
              טכנולוגי מתפתח ומשתנה ללא הרף. המדריכים שלנו עוברים תהליך מיון
              קפדני והכשרה מקצועית מקיפה, המקנה להם ידע עדכני וכלים חדשניים
              להוראה. הם נלהבים להוראה והדרכת ילדים, מחויבים לעזור להם לפתח את
              מלוא הפוטנציאל שלהם ולהעביר את החומר באופן חווייתי ומהנה, תוך
              עידוד להשתתפות פעילה ומשמעותית בתהליך הלמידה.
            </p>

            <h1>שותפים ממליצים עלינו</h1>

            <div>
              <h4>
                הדס יולס קדם, מנהלת אגף פנאי והעשרה | עמותת סחלבים – מודיעין
              </h4>
              <p>
                תוכנית הלימודים סדורה ועומדת בסטנדרטים גבוהים להוראה. צוות
                המדריכים מקצועי ובעל מומחיות מוכחת בתחום.
              </p>
            </div>
            <div>
              <h4>ניר רשף, מנהל תחום חוגי שחמט והעשרה | המתנ"ס – קריית-אונו</h4>
              <p>
                דוד מנחה את הסדנאות השונות במקצועיות רבה, הוא פועל למען הכשרת
                הילדים באמצעות פיתוח מיומנויות חשיבה בתחומי המחשב וההנדסה, דרך
                חקר, בנייה ותכנות משחקים חווייתיים ומהנים.
              </p>
            </div>
            <div>
              <h4>סיון חנניה, רכזת ספורט והעשרה | לידר – גני תקווה</h4>
              <p>
                במהלך 2022 הובלנו ביחד עם הדור הבא והרשות המקומית, פרויקט מיוחד
                ומוצלח לקידום בנות בתחום החינוך טכנולוגי, שבו חשפנו עשרות בנות
                לקוד ויצירה טכנולוגית. המדריכים מעוררים השראה אצל המשתתפים בזכות
                התשוקה שלהם לפיתוח מוחות צעירים, והיכולת שלהם לטפח את הדמיון
                והיצירתיות של התלמידים
              </p>
            </div>
            <div>
              <h4>מירי פמפר, רכזת העשרה | חמש – שוהם</h4>
              <p>
                דוד מעביר את הידע בצורה מקצועית ובהירה, בסבלנות ונועם ובצורה
                חווייתית שמעוררת בקרב הילדים הרבה סקרנות ורצון ללמידה. בנוסף,
                תכני החוג מעוררים בילדים ערכים מוספים כמו פיתוח חשיבה יצירתית,
                פיתוח תחושת מסוגלות ויזמות. חוגי התכנות זכו תודות לכל אלה להצלחה
                רבה , המשובים שמועברים מדי שנה מצביעים על שביעות רצון גבוהה הן
                של הילדים והן של ההורים והחוג זוכה לציון גבוה.
              </p>
            </div>
          </div>
        </ScrollAnimation>
        {creatButton()}
        <AboutMethod />
        {creatButton()}
        <div>
          <div>
            <div className="comments-container">
              <h2>תלמידים ממליצים</h2>
              <SwiperCardsVideos data={Videos.data} />
            </div>
          </div>

          <div id="register">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitesSchools;
