import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { auth, logInConfirm, signOutConfirm, clearError } from "../../actions";
import gamerAvatar from "../../../assets/gamer.webp";
import UserRegister from "./user_register";
import UserLogin from "./user_login";

import dictionary from "../../dictionary/dictionary.json";

const { Buttons, Labels, Message } = dictionary;

class User extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      modalMode: null,
      showProfileMenu: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.logInConfirm(user);
      }
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true, showProfileMenu: false });
  }

  logSessionOut() {
    this.setState({ showProfileMenu: false });
    auth.signOut();
    this.props.signOutConfirm();
  }

  openRegister() {
    this.props.clearError();
    this.setState({
      show: true,
      showProfileMenu: false,
      modalMode: "Register",
    });
  }

  openSignIn() {
    this.props.clearError();
    this.setState({
      show: true,
      showProfileMenu: false,
      modalMode: "SignIn",
    });
  }

  renderStatus() {
    const { user } = this.props;
    const toastrConfirmOptions = {
      onOk: () => {
        this.logSessionOut();
      },
    };
    if (user.displayName) {
      return (
        <ul>
          <li style={{ color: "black" }}>
            {user.displayName.substring(0, 5)} {Labels.HN.Connected}
          </li>
          <li>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ showProfileMenu: false });
                toastr.confirm(
                  "האם אתה בטוח שאתה רוצה להתנתק?",
                  toastrConfirmOptions
                );
              }}
            >
              {Buttons.HN.Sign_Out}
            </a>
          </li>
        </ul>
      );
    }
    return (
      <ul>
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={this.openRegister.bind(this)}
          >
            {Buttons.HN.Register}
          </a>
        </li>
        <li>
          <a style={{ cursor: "pointer" }} onClick={this.openSignIn.bind(this)}>
            {Buttons.HN.Sign_In}
          </a>
        </li>
      </ul>
    );
  }

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }
    const currMsg = Message.HN[error.code]
      ? Message.HN[error.code]
      : Message.HN.GeneralLogInError;
    return <div className="error">{currMsg}</div>;
  }

  renderModalBody() {
    switch (this.state.modalMode) {
      case "Register":
        return (
          <Modal.Body>
            <UserRegister handleClose={this.handleClose.bind(this)} />
            {this.renderError()}
          </Modal.Body>
        );
      case "SignIn":
        return (
          <Modal.Body>
            <UserLogin handleClose={this.handleClose.bind(this)} />
            {this.renderError()}
          </Modal.Body>
        );
      default:
        return null;
    }
  }

  renderTitle() {
    switch (this.state.modalMode) {
      case "Register":
        return Labels.HN.Register_Form;
      case "SignIn":
        return Labels.HN.Login_Form;
      default:
        return null;
    }
  }

  renderModal() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        centered
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.renderTitle()}</Modal.Title>
        </Modal.Header>
        {this.renderModalBody()}
      </Modal>
    );
  }

  render() {
    return (
      <div className="user">
        <div className="user-header">
          <img
            src={gamerAvatar}
            alt="gamerAvatar"
            style={
              this.props.user.displayName
                ? { border: "2px solid chartreuse" }
                : { border: "2px solid red" }
            }
            onClick={() =>
              this.setState({ showProfileMenu: !this.state.showProfileMenu })
            }
          />
        </div>
        <div
          className={`user-menu ${this.state.showProfileMenu ? "active" : ""}`}
        >
          {this.renderStatus()}
          {this.renderModal()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    error: state.auth.error,
  };
}

export default connect(mapStateToProps, {
  logInConfirm,
  signOutConfirm,
  clearError,
})(User);
