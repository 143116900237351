import React from "react";

const styles = {
  main: {
    margin: "0 auto",
    maxWidth: "800px",
  },
  respContainer: {
    width: "100%",
    height: "0",
    paddingBottom: "56.25%",
    position: "relative",
    boxSizing: "border-box",
  },
  respIframe: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
};

const VideoBlock = (props) => {
  const { id } = props;
  return (
    <div style={styles.main}>
      <div style={styles.respContainer}>
        <iframe
          style={styles.respIframe}
          src={`https://www.youtube.com/embed/${id}?rel=0`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
export default VideoBlock;
