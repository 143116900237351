import React from "react";
import { Card } from "react-bootstrap";
import defaultImg from "../../../assets/web_cover_sm.jpg";

const ContentCards = (props) => {
  return (
    <div className="syllabus-container">
      {props.cards.map((item, index) => {
        return (
          <Card className="syllabus-card" key={index}>
            <Card.Img
              src={`https://firebasestorage.googleapis.com/v0/b/hadoraba-24e9c.appspot.com/o/${item.image}.png?alt=media&token=${item.imageToken}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultImg;
              }}
            />
            {/*{item.image}*/}
            <Card.Body className="syllabus-body">
              <Card.Title className="syllabus-title">{item.subject}</Card.Title>
              <Card.Text className="syllabus-content">{item.content}</Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};
export default ContentCards;
