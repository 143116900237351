import React from "react";

const regular = props => {
  if (!props.back)
    return (
      <div className="header">
        <h2 className="header-title">{props.title}</h2>
      </div>
    );
  return (
    <div>
      <div className="header-container">
        <button
          className="btn btn-success"
          onClick={() => {
            window.history.back();
          }}
        >
          <span className="glyphicon glyphicon-circle-arrow-right" /> חזור
        </button>
      </div>
      <div className="header">
        <h1 className="header-title">{props.title}</h1>
      </div>
    </div>
  );
};

const Header = props => {
  return regular(props);
};

export default Header;
