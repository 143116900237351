import _ from "lodash";
import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { fetchGames, deleteGame, fetchGamesForUser } from "../../actions";

const { Buttons, Labels } = require("../../dictionary/dictionary.json");

const ButtonStyle = {
  marginRight: "5px",
};

class GameList extends Component {
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.user.uid !== prevProps.user.uid &&
      this.props.type === "user"
    ) {
      this.props.fetchGamesForUser(this.props.user.uid);
    }
  }
  componentDidMount() {
    if (this.props.type === "editor") {
      this.props.fetchGames();
    }
    if (this.props.user.uid && this.props.type === "user") {
      this.props.fetchGamesForUser(this.props.user.uid);
    }
  }

  Delete(e) {
    this.props.deleteGame(e.target.id);
  }

  renderDelete(key) {
    if (
      this.props.user.displayName === process.env.REACT_APP_ADMIN ||
      this.props.user.uid === this.props.games[key].uid
    ) {
      return (
        <a
          className="btn btn-danger"
          style={ButtonStyle}
          id={key}
          onClick={this.Delete.bind(this)}
        >
          {Buttons.HN.Delete}
        </a>
      );
    }
  }
  renderGames() {
    let { games } = this.props;
    if (this.props.type === "user") {
      games = this.props.userGames;
    }
    return _.map(games, (game, key) => {
      return (
        <div className="student-container-new" key={key}>
          <Card className="game-container-new" key={key} id={key}>
            <Link to={`/playground/${game.game_id}`}>
              <Card.Img
                variant="top"
                src={`https://uploads.scratch.mit.edu/projects/thumbnails/${game.game_id}.png`}
                alt={game.game_name}
                className="zoom"
                width="240"
                height="160"
                style={{ borderRadius: "70px 30px 50px 30px" }}
              />
              <Card.Title className="top-right">
                {game.game_name.substring(0, 30)}
              </Card.Title>
            </Link>

            {/* <Card.Body>
              {this.renderDelete(key)}
              <Link
                className="btn btn-success"
                to={`/playground/${game.game_id}`}
                style={{ marginRight: "10px" }}
              >
                {Buttons.HN.Play}
              </Link>
          </Card.Body>*/}
          </Card>
        </div>
      );
    });
  }

  getTitle() {
    if (this.props.type === "user" && this.props.user.uid) {
      return Labels.HN.User_Games;
    } else if (this.props.type === "editor") {
      return Labels.HN.Editor_Games;
    }
    return null;
  }

  CheckIfTrue() {
    let { games } = this.props;
    if (this.props.type === "user") {
      games = this.props.userGames;
    }
    const infinite = _.size(games) > 3 ? true : false;

    return infinite;
  }

  render() {
    if (this.props.type === "user" && !this.props.user.uid) {
      return null;
    }
    return (
      <div className="library-container2">
        <div>{this.renderGames()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    games: state.playground.games,
    userGames: state.playground.userGames,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, {
  fetchGames,
  deleteGame,
  fetchGamesForUser,
})(GameList);
