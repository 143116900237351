import React from "react";
import "./contact.css";
import ContactUs from "./contactUs";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-us">
        <div className="contact-title">
          <h2>בואו נהיה בקשר</h2>
          <div style={{ textAlign: "center" }}>
            <a
              className="btn btn-success"
              href={"https://wa.me/972554320014"}
              target="_blank"
              rel="noopener noreferrer"
            >
              לחצו עליי לשיחת וואטסאפ
            </a>
          </div>
        </div>
        <div className="contact-box">
          <div className="contact-card contact-form">
            <ContactUs />
          </div>
          <div className="contact-card contact-info">
            <h3>פרטי קשר</h3>
            <div class="infoBox">
              <div>
                <span>
                  <i class="fas fa-map-marker"></i>
                </span>
                <p>
                  <br />
                  תמר נווה אפרים 17, יהוד-מונסון
                  <br />
                  מיקוד 6019000, ישראל
                </p>
              </div>
              <div>
                <span>
                  <i class="fas fa-phone"></i>
                </span>
                <p>
                  <br />
                  <a href="tel:0554320014">055-4320014</a>
                </p>
              </div>
              <div>
                <span>
                  <i class="fas fa-envelope"></i>
                </span>
                <p>
                  <br />
                  <a href="mailto:hadoraba@gmail.com">hadoraba@gmail.com</a>
                </p>
              </div>

              <ul className="sci">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/hadoraba"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>

                {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/hadoraba"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>*/}
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/hador.aba/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/@hadoraba?lang=en"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#fff"
                        d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/hadoraba"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#fff"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCDsp1g0XY_ahqB5SqOMSO5A?sub_confirmation=1"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-card contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.5576618728514!2d34.87631208558279!3d32.02709372915094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d356f4d1d0535%3A0xda4ac03c26254456!2z16rXnteoIDE3LCDXmdeU15XXkw!5e0!3m2!1siw!2sil!4v1647092240779!5m2!1siw!2sil"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
