import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";

import _ from "lodash";
import { databaseFire } from "../../actions/firebase-blog";

const BlocksByCategory = (props) => {
  const [posts, setPosts] = useState([]);
  const { category } = props.match.params;
  useEffect(() => {
    const search = async () => {
      const data = {};
      const querySnapshot = await databaseFire
        .collection("posts")
        .where("category", "==", category)
        .get();
      querySnapshot.forEach((doc) => {
        data[doc.id] = doc.data();
      });

      setPosts(data);
    };

    search();
  }, []);

  const renderPosts = _.map(posts, (post, key) => {
    return (
      <div key={key} className="block-container">
        <Link to={`/wiki/blocks/${category}/${key}`}>
          <div className="block-box-image">{renderHTML(post.blockImg)}</div>
          <div className="block-content">
            <h4>{post.blockTitle}</h4>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <div style={{ textAlign: "center" }}>
      <h3 style={{ color: "red" }}> לחצו על הבלוק לקבלת הסבר מפורט</h3>
      <div className="blocks-container">{renderPosts}</div>
    </div>
  );
};
export default BlocksByCategory;
