import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Card } from "react-bootstrap";
import Header from "../helpers/header";

import { databaseFire } from "../../actions/firebase-blog";

const ClassesByYear = (props) => {
  const { year } = props.match.params;
  const [classes, setClasses] = useState([]);
  const [curryear, setCurrYear] = useState(year);
  useEffect(() => {
    const search = async () => {
      let data = {};
      const startOfYear = new Date(`${curryear}-01-01T00:00:00Z`);
      const endOfYear = new Date(`${curryear}-12-31T23:59:59Z`);
      const querySnapshot = await databaseFire
        .collection("files")
        .where("fileFolder", "==", "students-webs")
        .where("fileTimeStamp", ">=", startOfYear)
        .where("fileTimeStamp", "<=", endOfYear)
        .get();
      data = _.uniqBy(querySnapshot.docs.map((doc) => doc.data()["fileMD1"]));
      console.log("data", data);

      setClasses(data);
    };

    search();
    window.scrollTo(0, 0);
  }, []);
  const renderClasses = () => {
    return _.map(classes, (location, key) => {
      return (
        <div className="student-container" key={key}>
          <Card style={{ backgroundColor: "black", width: "18rem" }}>
            <Card.Img
              variant="top"
              src={"/images/graduate-centers.webp"}
              alt={location}
            />
            <Card.Body>
              <Card.Title>{location}</Card.Title>
              <Link
                className="btn btn-primary"
                to={`/library/classes/classesnew/${curryear}/${location}`}
              >
                כנסו לפרויקטים
              </Link>
            </Card.Body>
          </Card>
        </div>
      );
    });
  };

  return (
    <div className="library-container2">
      <Header title={`מחזור ${curryear}`} back />

      <div>{renderClasses()}</div>
    </div>
  );
};

export default ClassesByYear;
