import React, { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import { databaseFire } from "../../actions/firebase-blog";

const DynamicBlock = (props) => {
  const [currPost, setcurrPost] = useState([]);
  const [pageStatus, setpageStatus] = useState("loading");
  const pageID = props.pageID;
  const block = props.block;

  //todo: need to fetch only the block images and not all the posts
  useEffect(() => {
    const search = async () => {
      try {
        const querySnapshot = await getDataByEnv();

        if (querySnapshot.docs[0].exists) {
          setcurrPost(querySnapshot.docs[0].data());
          setpageStatus("pageFound");
        } else {
          setpageStatus("pageNotFound");
        }
      } catch {
        setpageStatus("pageNotFound");
      }
    };

    search();
  }, [pageID]);

  const getDataByEnv = async () => {
    let querySnapshot = {};
    if (process.env.NODE_ENV === "development") {
      querySnapshot = await databaseFire
        .collection("articles")
        .where("articleID", "==", pageID)
        //.where("prodMode", "==", "dev")
        .where("location", "==", block)
        .limit(1)
        .get();

      //console.log("querySnapshot", querySnapshot.docs[0].data());
    } else {
      querySnapshot = await databaseFire
        .collection("articles")
        .where("articleID", "==", pageID)
        .where("location", "==", block)
        .where("prodMode", "==", "prod")
        .limit(1)
        .get();
    }
    return querySnapshot;
  };

  const renderBlog = () => {
    if (pageStatus === "loading") {
      return "";
    } else if (pageStatus === "pageFound") {
      if (currPost.body) {
        return (
          <div>
            <h1>{currPost.articleTitle}</h1>
            <div>{renderHTML(currPost.body)}</div>
          </div>
        );
      }
    } else {
      return <div>ERROR</div>;
    }
  };

  return (
    <div>
      <div>{renderBlog()}</div>
    </div>
  );
};
export default DynamicBlock;
