import _ from "lodash";
import {
  FETCH_GAMES,
  FETCH_GAMES_ERROR,
  FETCH_GAMES_BY_USER,
  FETCH_GAMES_BY_USER_ERROR,
  CREATE_GAME,
  CREATE_GAME_ERROR,
  DELETE_GAME,
  DELETE_GAME_ERROR
} from "../actions/types";

const INITAL_STATE = {
  games: {},
  error: "",
  userGames: {}
};
export default function(state = INITAL_STATE, action) {
  switch (action.type) {
    case FETCH_GAMES:
      return { ...state, games: action.payload, error: "" };
    case FETCH_GAMES_ERROR:
      return { ...state, error: action.payload };
    case FETCH_GAMES_BY_USER:
      return { ...state, userGames: action.payload, error: "" };
    case FETCH_GAMES_BY_USER_ERROR:
      return { ...state, error: action.payload };
    case CREATE_GAME:
      const newGames = { ...state.userGames, ...action.payload };
      return { ...state, userGames: newGames, error: "" };
    case CREATE_GAME_ERROR:
      return { ...state, error: action.payload };
    case DELETE_GAME:
      const gamesAfterDelete = _.omit(state.userGames, action.payload);
      return { ...state, userGames: gamesAfterDelete, error: "" };
    case DELETE_GAME_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
